import type { InputGroupProps } from '@chakra-ui/react'
import { Icon, IconButton, Input, InputGroup, InputRightElement, useColorModeValue } from '@chakra-ui/react'
import type { FC } from 'react'
import { useEffect, useRef, memo } from 'react'
import { FiXCircle } from 'react-icons/fi'

import { useListFilterContext } from '@app/pages/shared/listFilterContext'

interface Props extends InputGroupProps {
  field: string
  placeholder?: string
}

const FieldSearchInput: FC<Props> = ({ ...rest }) => {
  const { listFilter: defaultValue, setListFilter } = useListFilterContext()
  const ref = useRef<HTMLInputElement>()

  useEffect(() => {
    if (ref?.current) {
      ref.current.value = defaultValue
    }
  }, [defaultValue])

  const onChange = ({ target: { value } }) => {
    setListFilter(value)
  }

  const clearSearch = () => {
    if (ref?.current) {
      ref.current.value = ''
    }

    setListFilter(null)
  }

  const { placeholder, ...inputGroupProps } = rest

  return (
    <InputGroup {...inputGroupProps} color={useColorModeValue('gray.500', 'gray.100')}>
      <Input
        ref={ref}
        autoFocus
        data-cy="field-search-input"
        defaultValue={defaultValue}
        onChange={onChange}
        placeholder={placeholder}
      />
      <InputRightElement color="muted">
        <IconButton
          aria-label="Clear search"
          icon={<Icon as={FiXCircle} color="gray" />}
          onClick={clearSearch}
          variant="text"
        />
      </InputRightElement>
    </InputGroup>
  )
}

export default memo(FieldSearchInput)
