import { AvatarGroup } from '@chakra-ui/react'
import type { FC } from 'react'
import { useEffect } from 'react'
import type { SubscriptionHandler } from 'urql'

import cardAvatarSize from '@app/shared/cards/cardAvatarSize'
import TooltipAvatar from '@app/shared/tooltipAvatar'
import profileImage from '@app/utils/profileImage'
import type { MapPresencesSubscription } from '@graphql/queries'
import { useMapPresencesSubscription, useMapPresenceRemoveMutation } from '@graphql/queries'
import type { User } from '@graphql/types'

type Props = {
  strategyId: string
}

type HandleSubscription = SubscriptionHandler<MapPresencesSubscription, Pick<User, 'id' | 'email' | 'name'>[]>
const handleSubscription: HandleSubscription = (_messages, response) => response?.crudOperation?.users || []

const UserPresences: FC<Props> = ({ strategyId }) => {
  // AnyCable doesn't offer a way to hook into the unsubscription of an individual subscription,
  // so we need to remove the presence when the component unmounts.
  const [, removePresence] = useMapPresenceRemoveMutation()
  const [result] = useMapPresencesSubscription(
    {
      variables: {
        strategyId
      },
      pause: !strategyId
    },
    handleSubscription
  )

  useEffect(() => {
    const unloadCallback = (_event) => {
      removePresence({ input: { strategyId } })
    }

    // handle the user closing the browser, rather than navigating away from the map
    window.addEventListener('beforeunload', unloadCallback)

    return () => {
      window.removeEventListener('beforeunload', unloadCallback)

      // This direct call handles the user navigating away from the map
      removePresence({ input: { strategyId } })
    }
  }, [])

  const users = result.data

  if (!users) {
    return null
  }

  return (
    <AvatarGroup max={10}>
      {users.map((user) => (
        <TooltipAvatar
          key={user.id}
          name={user.name}
          size={cardAvatarSize('normal', 'normal')}
          src={profileImage(user.email)}
        />
      ))}
    </AvatarGroup>
  )
}

export default UserPresences
