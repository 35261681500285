import type { InputProps } from '@chakra-ui/react'
import type { FC, ReactNode } from 'react'

import useInlineEditorProps from '@app/hooks/useInlineEditorProps'
import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import RawSelectInput from '@app/next/forms/selectInput'
import type { MapDomainObject } from '@app/types'

interface Props extends InputProps {
  domainObject: MapDomainObject
  name: string
  defaultValue?: string
  children: ReactNode
}

const SelectInput: FC<Props> = ({ domainObject, name, defaultValue, children, ...rest }) => {
  const { onChange, onBlur } = useInlineEditorProps<HTMLSelectElement>(domainObject)
  const { user } = useStoreCurrentUser()
  const editor = ['admin', 'editor'].includes(user?.role)

  const inlineStyles = {
    width: '100%',
    _disabled: {
      cursor: 'default'
    }
  }

  return (
    <RawSelectInput
      name={name}
      defaultValue={defaultValue}
      variant="unstyled"
      // @ts-expect-error - `onChange` and `onBlur` are required
      onChange={onChange}
      // @ts-expect-error - `onChange` and `onBlur` are required
      onBlur={onBlur}
      data-1p-ignore
      isDisabled={!editor}
      {...inlineStyles}
      {...rest}
    >
      {children}
    </RawSelectInput>
  )
}

export default SelectInput
