import {
  Badge,
  Box,
  Text,
  Tooltip,
  Flex,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  HStack,
  Stack,
  Spacer
} from '@chakra-ui/react'
import type { FC } from 'react'
import { Fragment } from 'react'

import { useAiAssistantEvalResultsQuery } from '@graphql/queries'

const generateId = (() => {
  let count = 0
  return (prefix: string) => {
    count += 1
    return `${prefix}-${count}`
  }
})()

const MonoBadge: FC<{
  text: string
  colorScheme: string
  isLast?: boolean
  tooltip?: string
}> = ({ text, colorScheme, isLast, tooltip }) => (
  <Tooltip maxW="800px" hasArrow label={tooltip}>
    <Badge mr={isLast ? 0 : 1} fontFamily="mono" fontSize="xs" textTransform="lowercase" colorScheme={colorScheme}>
      {text}
    </Badge>
  </Tooltip>
)

const formatContextTooltip = (contextJson: string | undefined) => {
  if (!contextJson) {
    return 'No context available'
  }
  return contextJson
}

interface MetricValue {
  value: number
  retryPercent?: number
  eval: string
  contextSnapshot?: string
}

interface AssistantData {
  name: string
  eval: {
    name: string
    retryBelow?: number | null
  }
  rows: Array<{
    assistant: string
    model: string
    values: MetricValue[]
  }>
}

interface GroupedData {
  name: string
  eval: {
    name: string
    retryBelow?: number | null
  }
  rows: Record<
    string,
    {
      assistant: string
      model: string
      values: MetricValue[]
    }
  >
}

const calculateChangeType = (current: number, previous: number | null): 'up' | 'down' | undefined => {
  if (previous === null) {
    return undefined
  }
  if (current > previous) {
    return 'up'
  }
  if (current < previous) {
    return 'down'
  }
  return undefined
}

interface EvalResult {
  assistantName?: string
  assistantFingerprint?: string
  model: string
  score?: number
  processedState: string
  eval: {
    name: string
    retryBelow?: number | null
  }
  contextSnapshot?: string
}

const transformData = (results: EvalResult[]): AssistantData[] => {
  const groupedByAssistant = results.reduce(
    (acc, result) => {
      const { assistantName } = result
      if (!acc[assistantName]) {
        acc[assistantName] = {
          name: assistantName,
          eval: result.eval,
          rows: {}
        }
      }

      if (!acc[assistantName].rows[result.assistantFingerprint]) {
        acc[assistantName].rows[result.assistantFingerprint] = {
          assistant: result.assistantFingerprint,
          model: result.model,
          values: []
        }
      }

      const relevantResults = results.filter(
        (r) => r.assistantFingerprint === result.assistantFingerprint && r.eval.name === result.eval.name
      )

      const avgScore = Math.round(relevantResults.reduce((sum, r) => sum + r.score, 0) / relevantResults.length)

      const retryPercent = Math.round(
        (relevantResults.filter((r) => r.processedState === 'triggered_retry').length / relevantResults.length) * 100
      )

      if (!acc[assistantName].rows[result.assistantFingerprint].values.find((v) => v.eval === result.eval.name)) {
        acc[assistantName].rows[result.assistantFingerprint].values.push({
          value: avgScore,
          retryPercent,
          eval: result.eval.name,
          contextSnapshot: result.contextSnapshot
        })
      }

      return acc
    },
    {} as Record<string, GroupedData>
  )

  return Object.values(groupedByAssistant).map((group) => ({
    name: group.name,
    eval: group.eval,
    rows: Object.values(group.rows)
  }))
}

const getMetrics = (section: AssistantData) => {
  const allEvals = new Set<string>()
  section.rows.forEach((row) => {
    row.values.forEach((value) => {
      allEvals.add(value.eval)
    })
  })
  return Array.from(allEvals)
}

const getMaxMetrics = (data: AssistantData[]) =>
  Math.max(...data.flatMap((section) => section.rows.map((row) => row.values.length)))

const calculateTotalDataColumns = (metricsCount: number) => metricsCount * 2

const AssistantHeader: FC<{ name: string; totalColumns: number }> = ({ name, totalColumns }) => (
  <Tr>
    <Th px={4} py={6} colSpan={totalColumns + 1}>
      <Stack spacing="2">
        <Stack alignItems="center" direction={{ base: 'column', md: 'row' }} spacing="3">
          <HStack>
            <Text fontSize={{ base: 'lg', md: 'xl' }} fontWeight="medium" wordBreak="break-word">
              {name}
            </Text>
          </HStack>
          <Spacer />
        </Stack>
      </Stack>
    </Th>
  </Tr>
)

const TableHeader: FC<{ metrics: string[]; maxMetrics: number; columnWidth: string }> = ({
  metrics,
  maxMetrics,
  columnWidth
}) => (
  <>
    <Tr>
      <Th w="20%" />
      {metrics.map((metric) => (
        <Th key={metric} textAlign="center" colSpan={2}>
          {metric}
        </Th>
      ))}
      {Array(maxMetrics - metrics.length)
        .fill(null)
        .map(() => (
          <Th key={generateId('empty-header')} colSpan={2} />
        ))}
    </Tr>
    <Tr>
      <Th w="20%" rowSpan={1}>
        <MonoBadge text="assistant" colorScheme="purple" />
        <MonoBadge text="model" colorScheme="blue" isLast />
      </Th>
      {metrics.map((metric) => (
        <Fragment key={`header-${metric}`}>
          <Th w={columnWidth} textAlign="center">
            Score
          </Th>
          <Th w={columnWidth} textAlign="center">
            Retries
          </Th>
        </Fragment>
      ))}
      {Array(maxMetrics - metrics.length)
        .fill(null)
        .map(() => (
          <Fragment key={generateId('empty-metric')}>
            <Th w={columnWidth} textAlign="center" />
            <Th w={columnWidth} textAlign="center" />
          </Fragment>
        ))}
    </Tr>
  </>
)

const TableRow: FC<{
  row: {
    assistant: string
    model: string
    values: MetricValue[]
  }
  metricsCount: number
  columnWidth: string
  previousValues?: MetricValue[]
}> = ({ row, metricsCount, columnWidth, previousValues }) => (
  <Tr>
    <Td w="20%">
      <MonoBadge
        text={row.assistant}
        colorScheme="purple"
        tooltip={formatContextTooltip(row.values[0]?.contextSnapshot)}
      />
      <MonoBadge text={row.model} colorScheme="blue" isLast />
    </Td>
    {row.values.map((value) => {
      const previousValue = previousValues ? previousValues.find((v) => v.eval === value.eval)?.value : null
      const previousRetry = previousValues ? previousValues.find((v) => v.eval === value.eval)?.retryPercent : null
      const scoreChange = calculateChangeType(value.value, previousValue)
      const retryChange = calculateChangeType(value.retryPercent || 0, previousRetry)

      return (
        <Fragment key={`${row.assistant}-${value.eval}`}>
          <Td w={columnWidth} textAlign="center">
            {value.value}%
            {scoreChange && (
              <Text as="span" color={scoreChange === 'up' ? 'green.500' : 'red.500'}>
                {scoreChange === 'up' ? '↑' : '↓'}
              </Text>
            )}
          </Td>
          <Td w={columnWidth} textAlign="center">
            {value.retryPercent}%
            {retryChange && (
              <Text as="span" color={retryChange === 'up' ? 'red.500' : 'green.500'}>
                {retryChange === 'up' ? '↑' : '↓'}
              </Text>
            )}
          </Td>
        </Fragment>
      )
    })}
    {Array.from({ length: Math.max(0, metricsCount - row.values.length) }).map(() => (
      <Fragment key={generateId(`empty-value-${row.assistant}`)}>
        <Td w={columnWidth} textAlign="center">
          -
        </Td>
        <Td w={columnWidth} textAlign="center">
          -
        </Td>
      </Fragment>
    ))}
  </Tr>
)

const PerformanceMetrics: FC = () => {
  const [result] = useAiAssistantEvalResultsQuery({
    variables: {
      states: ['complete', 'triggered_retry']
    }
  })

  const { data: queryData, fetching, error } = result

  if (fetching) {
    return (
      <Box overflow="hidden" p={4} bg="white" borderRadius="lg">
        <Text>Loading...</Text>
      </Box>
    )
  }

  if (error || !queryData?.aiAssistantEvalResults) {
    return (
      <Box overflow="hidden" p={4} bg="white" borderRadius="lg">
        <Text>Error loading metrics</Text>
      </Box>
    )
  }

  const transformedData = transformData(queryData.aiAssistantEvalResults)
  const maxMetricsCount = getMaxMetrics(transformedData)
  const totalDataColumns = calculateTotalDataColumns(maxMetricsCount)
  const columnWidth = `${80 / totalDataColumns}%`

  return (
    <Box overflow="hidden" bg="white" borderRadius="lg">
      <Box p={4} borderBottomWidth={1}>
        <Flex align="center" justify="space-between">
          <Box>
            <Text fontSize="lg" fontWeight="medium">
              Performance Metrics
            </Text>
            <Text color="gray.600" fontSize="sm">
              Version comparison across AI assistants
            </Text>
          </Box>
        </Flex>
      </Box>

      <Box p={4}>
        {transformedData.map((section) => (
          <Box
            key={`section-${section.name}`}
            overflowX="auto"
            mb={section.name !== transformedData[transformedData.length - 1].name ? 8 : 0}
          >
            <Table variant="simple">
              <Thead>
                <AssistantHeader name={section.name} totalColumns={totalDataColumns} />
                <TableHeader metrics={getMetrics(section)} maxMetrics={maxMetricsCount} columnWidth={columnWidth} />
              </Thead>
              <Tbody>
                {section.rows.map((row) => (
                  <TableRow
                    key={`${section.name}-${row.assistant}`}
                    row={row}
                    metricsCount={maxMetricsCount}
                    columnWidth={columnWidth}
                    previousValues={section.rows[section.rows.indexOf(row) - 1]?.values}
                  />
                ))}
              </Tbody>
            </Table>
          </Box>
        ))}
      </Box>
    </Box>
  )
}

export default PerformanceMetrics
