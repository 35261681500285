import { Stack, useBoolean } from '@chakra-ui/react'
import type { FC } from 'react'

import ApplyOrganizationMetricStatIntervals from '@app/pages/settings/organization/components/applyOrganizationMetricStatIntervals'
import { DurationInput, ToggleInput } from '@app/shared/rawForms'
import type { Errors } from '@app/shared/rawForms/useForm'
import TextDivider from '@app/shared/textDivider'
import type { Organization } from '@graphql/types'

interface Props {
  organization?: Pick<Organization, 'metricDefaults'>
  errors?: Errors
}

const StatIntervals: FC<Props> = ({ organization = null, errors = null }) => {
  const metricDefaults = organization?.metricDefaults || {}
  const [isChecked, setIsChecked] = useBoolean(!!metricDefaults?.period_to_date)

  return (
    <Stack spacing={4}>
      <ToggleInput
        isChecked={isChecked}
        label="Month to date (MTD), Quarter to date (QTD), Year to date (YTD)"
        labelSize="sm"
        name="metricDefaults.period_to_date"
        onChange={setIsChecked.toggle}
        defaultValue={isChecked}
      />
      {!isChecked && (
        <>
          <TextDivider>or</TextDivider>
          <DurationInput
            label="Interval Period 1"
            labelSize="sm"
            name="metricDefaults.stat_intervals.0"
            defaultValue={metricDefaults.stat_intervals?.[0] || 'P7D'}
            errors={errors}
          />
          <DurationInput
            label="Interval Period 2"
            labelSize="sm"
            name="metricDefaults.stat_intervals.1"
            defaultValue={metricDefaults.stat_intervals?.[1] || 'P6W'}
            errors={errors}
          />
          <DurationInput
            label="Interval Period 3"
            labelSize="sm"
            name="metricDefaults.stat_intervals.2"
            defaultValue={metricDefaults.stat_intervals?.[2] || 'P12M'}
            errors={errors}
          />
        </>
      )}
      <ApplyOrganizationMetricStatIntervals />
    </Stack>
  )
}

export default StatIntervals
