import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  type ModalProps,
  VisuallyHiddenInput
} from '@chakra-ui/react'
import debounce from 'lodash/debounce'
import omitBy from 'lodash/omitBy'
import { useCallback, useRef, useState } from 'react'
import type { FormEventHandler, ChangeEventHandler, FC } from 'react'

import SourceConfiguration from './sourceConfiguration'
import SourceNameSelect from './sourceNameSelect'

import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import { RawForm } from '@app/shared/rawForms/form'
import { useStore } from '@app/store'
import type { JSONValue } from '@app/types'
import { CredentialCreate } from '@graphql/documents/credential.graphql'
import type { Credential } from '@graphql/types'

type Props = Partial<ModalProps> & {
  credential?: Credential | null
  isOpen: boolean
}

const MetricIntegrationModal: FC<Props> = ({ credential, onClose: propOnClosed, ...props }) => {
  const [step, setStep] = useState(credential ? 1 : 0)
  const [sourceName, setSourceName] = useState(credential?.sourceName || '')
  const formRef = useRef<HTMLFormElement>(null)
  const [isValid, setIsValid] = useState(!!credential)
  const [isLoading, setIsLoading] = useState(false)
  const actionMutation = useStore.use.actionMutation()
  const updateObject = useStore.use.updateObject()

  const { user } = useStoreCurrentUser()

  const onChange = useCallback(
    debounce<ChangeEventHandler<HTMLFormElement>>(() => {
      setIsValid(formRef.current?.checkValidity())
    }, 50),
    []
  )

  if (!user) {
    return null
  }

  const nextStep = () => {
    setStep(step + 1)
  }

  const prevStep = () => {
    setStep(step - 1)
  }

  const onClose = () => {
    propOnClosed()

    if (!credential) {
      setIsValid(false)
      setStep(0)
      setSourceName('')
    }
  }

  const closeAndReset = () => {
    onClose()
    formRef.current?.reset()
  }

  const stepParts = () => {
    switch (step) {
      case 0:
        return {
          body: <SourceNameSelect value={sourceName} onClick={setSourceName} />,
          footer: (
            <ButtonGroup>
              <Button isDisabled type="button" variant="secondary">
                Prev
              </Button>
              <Button as={Link} isDisabled={!sourceName} onClick={nextStep} type="button" variant="primary">
                Next
              </Button>
            </ButtonGroup>
          )
        }
      case 1:
        return {
          body: (
            <Box px={6}>
              <SourceConfiguration
                credential={credential}
                sourceName={sourceName}
                formRef={formRef}
                isValid={isValid}
                onChange={onChange}
              />
            </Box>
          ),
          footer: (
            <ButtonGroup>
              {!credential && (
                <Button onClick={prevStep} type="button" variant="secondary">
                  Prev
                </Button>
              )}
              <Button isDisabled={!isValid} isLoading={isLoading} type="submit" variant="primary">
                Save
              </Button>
            </ButtonGroup>
          )
        }
      default:
        return {
          body: null,
          footer: (
            <Button onClick={closeAndReset} type="button" variant="primary">
              Close
            </Button>
          )
        }
    }
  }

  const { body, footer } = stepParts()
  const header = credential ? 'Edit metric integration' : 'Add metric integration'

  const onSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault()
    // This stopPropagation is necessary to prevent the other form on the page, MetricSourceCreate from submitting.
    e.stopPropagation()

    setIsLoading(true)

    const formData = new FormData(e.currentTarget)
    let input: Record<string, JSONValue> = {}

    // Fix a persnickety type issue so TS doesn't complain about `File`s
    for (const entry of formData.entries()) {
      const [key, value] = entry
      if (!(value instanceof File)) {
        input[key] = value
      }
    }

    // avoid overwriting any secrets (which we don't send to the frontend) with blank values
    const secretFields = input.secretFields as string
    delete input.secretFields
    if (secretFields) {
      const secrets = secretFields.split(',')
      input = omitBy(input, (value, key) => !value && secrets.includes(key))
    }

    try {
      if (credential) {
        await updateObject({ credential: { id: credential.id, ...input } })
      } else {
        await actionMutation(CredentialCreate, input)
      }
    } finally {
      setIsLoading(false)
      onClose()
    }
  }

  return (
    <Modal isCentered onClose={closeAndReset} {...props}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{header}</ModalHeader>
        <ModalCloseButton />
        <Divider />
        <RawForm id="metric-integration-form" variant="drawer" onSubmit={onSubmit} onChange={onChange} ref={formRef}>
          <VisuallyHiddenInput defaultValue={sourceName} name="sourceName" />
          {credential && <VisuallyHiddenInput defaultValue={credential.id} name="id" />}
          <ModalBody px="0">{body}</ModalBody>
          <ModalFooter>{footer}</ModalFooter>
        </RawForm>
      </ModalContent>
    </Modal>
  )
}

export default MetricIntegrationModal
