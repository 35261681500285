import { Box, Button, Center, Checkbox, Link as ChakraLink, Stack, useCheckboxGroup } from '@chakra-ui/react'
import { useCallback, useMemo } from 'react'
import { Link } from 'react-router-dom'

import StrategyListActions from './components/strategyListActions'
import StrategyName from './components/strategyName'

import useGetObjectPage from '@app/hooks/useGetObjectPage'
import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import CreateMapButton from '@app/pages/maps/components/createMapButton'
import { ListFilterContextProvider } from '@app/pages/shared/listFilterContext'
import MultiOps from '@app/pages/shared/multiOps'
import SearchRow from '@app/pages/shared/searchRow'
import SelectAllCheckboxHeader from '@app/pages/shared/selectAllCheckboxHeader'
import useGenerateSelectableTableRowFormatter from '@app/pages/shared/useGenerateSelectableTableRowFormatter'
import Can from '@app/shared/authorization/can'
import EmptyState from '@app/shared/emptyState/emptyState'
import { AppShell, Card, PageHeader, PageStack } from '@app/shared/layout'
import SuspenseLoader from '@app/shared/loaders/suspenseLoader'
import Pagination from '@app/shared/pagination'
import Table from '@app/shared/table'
import SortHeader from '@app/shared/table/components/sortHeader'
import { SortHeaderContextProvider } from '@app/shared/table/contexts/sortHeaderContext'
import type { Column } from '@app/shared/table/types'
import withAwait from '@app/shared/withAwait'
import type { MapDomainStrategy } from '@app/types'
import { Routes } from '@app/utils/routeHelpers'

type Strategy = MapDomainStrategy

type MakeColumns = (args: {
  getCheckboxProps: ReturnType<typeof useCheckboxGroup>['getCheckboxProps']
  selected: ReturnType<typeof useCheckboxGroup>['value']
  setSelected: ReturnType<typeof useCheckboxGroup>['setValue']
  formatDateTimeInUserTimeZone: ReturnType<typeof useStoreCurrentUser>['formatDateTimeInUserTimeZone']
}) => Column<Strategy>[]

const makeColumns: MakeColumns = ({ getCheckboxProps, selected, setSelected, formatDateTimeInUserTimeZone }) => [
  {
    header: <SelectAllCheckboxHeader collectionType="strategy" selected={selected} setSelected={setSelected} />,
    width: '5%',
    key: 'checkbox',
    cell: ({ data: strategy }) => (
      <Center>
        <Checkbox {...getCheckboxProps({ value: strategy.id })} />
      </Center>
    )
  },
  {
    header: <SortHeader field="name" title="Name" emptyState={false} />,
    key: 'name',
    width: '40%',
    cell: StrategyName
  },
  {
    header: 'Labels',
    key: 'labels',
    cell: ({ data }) => data?.labels?.map((label) => label.name)?.join(', ')
  },
  {
    header: <SortHeader field="createdAt" title="Created at" emptyState={false} />,
    key: 'createdAt',
    cell: ({ data }: { data: MapDomainStrategy }) => formatDateTimeInUserTimeZone(data.createdAt, 'L/d/yyyy')
  },
  {
    header: '',
    cell: function StrategyActions({ data: strategy }) {
      return <StrategyListActions strategy={strategy} />
    }
  }
]

const Populated = ({ collection, metadata }) => {
  const { page, limitValue, totalCount } = metadata
  const {
    value: selected,
    getCheckboxProps,
    setValue: setSelected
  } = useCheckboxGroup({
    defaultValue: []
  })
  const { formatDateTimeInUserTimeZone } = useStoreCurrentUser()

  const columns = useMemo(
    () => makeColumns({ getCheckboxProps, selected, setSelected, formatDateTimeInUserTimeZone }),
    [getCheckboxProps, selected, setSelected]
  )
  const onBulkDelete = useCallback(() => {
    setSelected([])
  }, [setSelected])
  const rowFormatter = useGenerateSelectableTableRowFormatter<MapDomainStrategy>('strategy', selected, setSelected)

  return (
    <Stack spacing="0">
      <SearchRow
        leftSummarySibling={<MultiOps type="strategy" selected={selected} fontSize="sm" onDelete={onBulkDelete} />}
        page={page}
        limitValue={limitValue}
        totalCount={totalCount}
      />
      <Box overflow="auto">
        <SortHeaderContextProvider defaultState={{ name: 'asc' }}>
          <Table columns={columns} data={collection} rowFormatter={rowFormatter} />
        </SortHeaderContextProvider>
        <Pagination px={6} py={4} {...{ ...metadata }} scrollContainer="main" />
      </Box>
    </Stack>
  )
}

const Empty = () => (
  <Box px={2}>
    <SearchRow page={0} limitValue={0} totalCount={0} />
    <EmptyState title="Looks like you do not have any maps yet" w="auto">
      <Button as={Link} to="/playbooks">
        Check out Playbooks for ideas
      </Button>
    </EmptyState>
  </Box>
)

const List = () => {
  const { collection, metadata } = useGetObjectPage('strategy')
  const subtitle = (
    <>
      Build separate strategy maps for each team or initiative.{' '}
      <ChakraLink color="link" href={Routes.docsStrategyConcepts} rel="noopener noreferrer" target="_blank">
        Learn more.
      </ChakraLink>
    </>
  )

  return (
    <AppShell>
      <ListFilterContextProvider searchField="filter">
        <PageStack>
          <PageHeader title="Maps" subtitle={subtitle}>
            <Can I="create" a="strategy">
              <CreateMapButton />
            </Can>
          </PageHeader>
          <Card p={0}>{collection.length ? <Populated collection={collection} metadata={metadata} /> : <Empty />}</Card>
        </PageStack>
      </ListFilterContextProvider>
    </AppShell>
  )
}

export default withAwait(
  List,
  'strategies',
  <AppShell>
    <SuspenseLoader />
  </AppShell>
)
