import { Box, Flex, Heading, HStack, Stack } from '@chakra-ui/react'
import type { FC } from 'react'

import useGetObject from '@app/hooks/useGetObject'
import AddWorkItemButton from '@app/next/changelog/components/addWorkItemButton'
import ContainerFilters from '@app/next/changelog/components/containerFilters'
import LayoutRow from '@app/next/changelog/components/layoutRow'
import SendReportButton from '@app/pages/shared/sendReportButton'
import Can from '@app/shared/authorization/can'
import { RecurringReportConfigurationServiceEnum } from '@graphql/types'

type Props = {
  strategyId: string
}

const Hero: FC<Props> = ({ strategyId }) => {
  const strategy = useGetObject(strategyId, 'strategy')

  return (
    <LayoutRow variant="naked">
      <Stack my={[4, null, 6]} data-testid="hero" spacing={4}>
        <HStack>
          <Heading flex="1" color="muted" size="sm">
            Changelog - {strategy.name}
          </Heading>
        </HStack>
        <Flex gap={2}>
          <Box flex={1}>
            <ContainerFilters strategyId={strategyId} />
          </Box>
          <Can I="create" an="entity">
            <AddWorkItemButton />
          </Can>
          <Can I="view" a="beta">
            <SendReportButton
              strategyId={strategyId}
              reportType={RecurringReportConfigurationServiceEnum.SendChangelog}
            />
          </Can>
        </Flex>
      </Stack>
    </LayoutRow>
  )
}

export default Hero
