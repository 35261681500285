import { Kbd, Text } from '@chakra-ui/react'
import type { FC } from 'react'
import { useEffect, useState } from 'react'

interface Props {
  inputRef: React.RefObject<HTMLTextAreaElement>
}

const ChatTextareaHelpText: FC<Props> = ({ inputRef, ...rest }) => {
  const [opacity, setOpacity] = useState(0)

  const eventListener = () => {
    if (inputRef?.current?.value.length) {
      setOpacity(100)
    } else {
      setOpacity(0)
    }
  }

  useEffect(() => {
    if (!inputRef?.current) {
      return () => {}
    }

    inputRef?.current?.addEventListener('keyup', eventListener)
    inputRef?.current?.addEventListener('blur', eventListener)

    return () => {
      inputRef?.current?.removeEventListener('keyup', eventListener)
      inputRef?.current?.removeEventListener('blur', eventListener)
    }
  }, [inputRef])

  return (
    <Text
      pos="absolute"
      zIndex={2}
      right={5}
      bottom={2}
      color="fg.subtle"
      fontSize="xs"
      {...rest}
      opacity={opacity}
      transition="opacity 200ms"
    >
      Use <Kbd color="black">shift+return</Kbd> for a new line
    </Text>
  )
}

export default ChatTextareaHelpText
