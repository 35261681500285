import { Button } from '@chakra-ui/react'
import type { FC, ReactNode } from 'react'
import { FiTrash2 } from 'react-icons/fi'

import ConfirmPopover from '@app/shared/confirmPopover'
import useToast from '@app/shared/toast'
import { useStore } from '@app/store'

type Props = {
  goalId: string
  children?: ReactNode
}

const defaultChildren = (
  <Button color="error" aria-label="Delete key result" leftIcon={<FiTrash2 />} variant="tertiary">
    Delete
  </Button>
)

const GoalDeleteButton: FC<Props> = ({ goalId, children = defaultChildren }) => {
  const deleteObject = useStore.use.deleteObject()
  const toast = useToast()

  const onConfirm = async () =>
    deleteObject({ id: goalId, classType: 'goal' }).then(() => {
      toast({ title: 'Deleted key result' })
    })

  return (
    <ConfirmPopover
      onConfirm={onConfirm}
      header="Delete key result"
      body="Are you sure? You can&#39;t undo this action afterwards."
      confirmButtonText="Delete"
    >
      {children}
    </ConfirmPopover>
  )
}

export default GoalDeleteButton
