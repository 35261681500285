import {
  Button,
  forwardRef,
  HStack,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Spacer,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import type { ButtonProps, ModalProps } from '@chakra-ui/react'
import sortBy from 'lodash/sortBy'
import type { FC, MutableRefObject } from 'react'
import { useCallback, useImperativeHandle, useMemo, useState } from 'react'
import { FiCheck, FiChevronDown } from 'react-icons/fi'

import type { CredentialSelectApi } from '../metricSourceShow'

import useGetObjects from '@app/hooks/useGetObjects'
import useGetObjectsByProperties from '@app/hooks/useGetObjectsByProperties'
import { METRIC_SOURCES } from '@app/lib/globals'
import MetricIntegrationModal from '@app/pages/settings/integrations/components/metricIntegrations/metricIntegrationModal'
import Nameplate from '@app/pages/settings/integrations/components/metricIntegrations/nameplate'
import type { Credential as GqlCredential } from '@graphql/types'
import { IntegrationSourceNamesEnum } from '@graphql/types'

type Credential = Pick<GqlCredential, 'id' | 'classType' | 'displayName' | 'sourceName' | 'label'>
type CredId = Credential['id'] | Credential['sourceName'] | ''
type OnSelect = (sourceName: string, credential: Credential) => void

type SourceNameProps = {
  sourceName: string
  label?: string
}

type SourceMenuItemProps = {
  credential: Credential | null
  sourceName: string
  onClick: OnSelect
  isSelected: boolean
}

export type Props = Partial<Omit<ButtonProps, 'onSelect'>> & {
  onSelect?: OnSelect
  credential?: Credential | null
  defaultSourceName?: string
  apiRef?: MutableRefObject<CredentialSelectApi>
}

const SourceName: FC<SourceNameProps> = ({ sourceName, label }) => {
  const source = METRIC_SOURCES[sourceName]

  if (source) {
    return <Nameplate sourceName={sourceName}>{label ? <span> &mdash; {label}</span> : ''}</Nameplate>
  }

  return (
    <HStack>
      <Text>{label || 'None'}</Text>
    </HStack>
  )
}

const SourceMenuItem: FC<SourceMenuItemProps> = ({ credential, sourceName, onClick, isSelected }) => (
  <MenuItem onClick={() => onClick(sourceName, credential)}>
    <HStack>
      <SourceName sourceName={credential?.sourceName || sourceName} label={credential?.label} />
      <Spacer />
      {isSelected ? <FiCheck /> : null}
    </HStack>
  </MenuItem>
)

const AddSourceModal: FC<Partial<ModalProps>> = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <MenuItem onClick={onOpen}>Add source...</MenuItem>

      <MetricIntegrationModal isOpen={isOpen} onClose={onClose} {...props} />
    </>
  )
}

const CredentialSelect: ReturnType<typeof forwardRef<Props, typeof MenuButton>> = forwardRef(
  ({ defaultSourceName, credential: credentialProp, onSelect, apiRef, ...buttonProps }, ref) => {
    const credentials = useGetObjects('credential')
    const [credential, setCredential] = useState(credentialProp)
    const [sourceName, setSourceName] = useState(credential?.sourceName || defaultSourceName || '')
    const [credId, setCredId] = useState<CredId>(credential?.id || sourceName)
    const showGoogle =
      useGetObjectsByProperties('integration', { sourceName: IntegrationSourceNamesEnum.Google }).length > 0
    const { isDisabled } = buttonProps

    useImperativeHandle(
      apiRef,
      () => ({
        reset: () => {}, // Matching the API that is expected via the typescript type.
        clear: () => {
          setCredId('')
          setSourceName('')
          setCredential(null)
        }
      }),
      []
    )

    const dynamicSources = useMemo(() => {
      const mapped = [
        ...(credentials || []).map((c) => ({
          sourceName: c.sourceName,
          credential: c
        })),
        {
          sourceName: 'MetricSources::Looker',
          credential: null
        },
        {
          sourceName: 'MetricSources::GoogleSheets',
          credential: null
        }
      ]

      if (showGoogle) {
        mapped.push({
          sourceName: 'MetricSources::GoogleAnalyticsV4',
          credential: null
        })
      }

      return sortBy(mapped, (c) => {
        const { display = '' } = METRIC_SOURCES[c.sourceName] || {}
        const basic = display || c.sourceName
        return c.credential ? `${basic} ${c.credential.label}` : basic
      })
    }, [credentials, showGoogle])

    const onClick: OnSelect = useCallback(
      (newSourceName, newCredential) => {
        if (!isDisabled) {
          setCredId(newCredential?.id || newSourceName)
          setSourceName(newSourceName)
          setCredential(newCredential)
          onSelect?.(newSourceName, newCredential)
        }
      },
      [onSelect, isDisabled]
    )

    return (
      <Menu>
        <MenuButton ref={ref} as={Button} rightIcon={<FiChevronDown />} variant="tertiary" {...buttonProps}>
          <SourceName sourceName={credential?.sourceName || sourceName} label={credential?.label} />
        </MenuButton>
        <MenuList overflowY="auto" maxH="450px">
          <AddSourceModal />
          <SourceMenuItem sourceName="" credential={null} onClick={onClick} isSelected={credId === ''} />
          <MenuDivider />
          <SourceMenuItem
            sourceName="MetricSources::Calculated"
            credential={null}
            onClick={onClick}
            isSelected={credId === 'MetricSources::Calculated'}
          />
          <SourceMenuItem
            sourceName="MetricSources::DummyData"
            credential={null}
            onClick={onClick}
            isSelected={credId === 'MetricSources::DummyData'}
          />
          <MenuDivider />
          {dynamicSources.map(({ sourceName: name, credential: cred }) => (
            <SourceMenuItem
              key={cred?.id || name}
              sourceName={name}
              credential={cred}
              onClick={onClick}
              isSelected={credId === cred?.id}
            />
          ))}
        </MenuList>
      </Menu>
    )
  }
)

export default CredentialSelect
