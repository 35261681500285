import type { TextProps } from '@chakra-ui/react'
import {
  Box,
  Icon,
  IconButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  Portal,
  Tooltip,
  useDisclosure
} from '@chakra-ui/react'
import type { FC } from 'react'
import { memo, useRef } from 'react'
import { useParams } from 'react-router-dom'

import useIsAnonymousUser from '@app/hooks/useIsAnonymousUser'
import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import ConfidenceRatingTag from '@app/next/domainObjects/confidenceRating/confidenceRatingTag'
import { SearchList, SearchListItem } from '@app/shared/searchList'
import { useStore } from '@app/store'
import type { CardSize } from '@app/types'
import ICON_MAP, { COLOR_MAP } from '@app/utils/iconMap'
import { toSentenceCase } from '@app/utils/stringHelpers'
import { ConfidenceRatingEnum } from '@graphql/queries'
import type { BasicCard, Metric, Strategy, Entity, NodeObjectInput } from '@graphql/types'

interface PlaceholderProps {
  size?: CardSize
}

const ConfidencePlaceholder: FC<PlaceholderProps> = memo(() => {
  const { strategyId } = useParams()
  const isAnonymousUser = useIsAnonymousUser(strategyId)

  if (strategyId && isAnonymousUser) {
    return null
  }

  return (
    <Tooltip aria-label="Confidence level" label="Confidence level">
      <IconButton
        color="fg.subtle"
        aria-label="Confidence level"
        icon={<Icon as={ICON_MAP.Confidence} />}
        variant="ghost"
      />
    </Tooltip>
  )
})

interface ShowProps {
  confidenceRating: ConfidenceRatingEnum
  showChevron?: boolean
  size?: CardSize
}

const ConfidenceRatingShow: FC<ShowProps> = ({ confidenceRating, showChevron = true, size = 'normal' }) => {
  const color = COLOR_MAP[`Confidence-${confidenceRating}`] || 'gray'
  const displayText = toSentenceCase(confidenceRating)

  return (
    <ConfidenceRatingTag
      showChevron={showChevron}
      rating={displayText}
      color={color}
      tagSize={size === 'normal' ? 'md' : 'lg'}
      fontSize={size === 'normal' ? 'sm' : 'lg'}
      tooltip=""
    />
  )
}

type ConfidenceTypes = Metric | BasicCard | Strategy | Entity

interface Props extends TextProps {
  domainObject: Pick<ConfidenceTypes, 'id' | 'classType' | 'confidenceRating'>
  size?: CardSize
}

const ConfidenceRatingPopover: FC<Props> = ({ domainObject, size = 'normal', ...rest }) => {
  const { user } = useStoreCurrentUser()
  const initialFocusRef = useRef()
  const { isOpen, onToggle, onClose } = useDisclosure()
  const updateObject = useStore.use.updateObject()
  const { id, classType, confidenceRating } = domainObject
  const editor = ['admin', 'editor'].includes(user?.role)

  const viewElement = confidenceRating ? (
    <ConfidenceRatingShow confidenceRating={confidenceRating} showChevron={editor} size={size} />
  ) : (
    <ConfidencePlaceholder size={size} {...rest} />
  )

  if (!editor) {
    return viewElement
  }

  const handleItemClick = (value: string) => {
    updateObject({ [classType]: { id, confidenceRating: value as ConfidenceRatingEnum } } as NodeObjectInput)

    onToggle()
  }

  return (
    <Popover initialFocusRef={initialFocusRef} isLazy isOpen={isOpen} onClose={onClose}>
      <PopoverTrigger>
        <Box cursor="pointer" onClick={onToggle}>
          {viewElement}
        </Box>
      </PopoverTrigger>
      <Portal>
        <PopoverContent maxW={36}>
          <PopoverArrow />
          <PopoverBody m={0} p={0}>
            <SearchList
              searchField="type"
              currentValue={confidenceRating}
              initialFocusRef={initialFocusRef}
              onChange={handleItemClick}
            >
              <SearchListItem text="None" value={null} />
              <SearchListItem text="On track" value={ConfidenceRatingEnum.OnTrack} />
              <SearchListItem text="At Risk" value={ConfidenceRatingEnum.AtRisk} />
              <SearchListItem text="Off Track" value={ConfidenceRatingEnum.OffTrack} />
            </SearchList>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  )
}

export default ConfidenceRatingPopover
