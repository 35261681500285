import sortBy from 'lodash/sortBy'
import type { FC } from 'react'
import { useRef, useEffect } from 'react'

import useGetObjects from '@app/hooks/useGetObjects'
import { SelectInput } from '@app/shared/rawForms'

type Props = {
  cardTypeId: string
}

const QueryExistingBasicCardFields: FC<Props> = ({ cardTypeId }) => {
  const ref = useRef<HTMLSelectElement>(null)

  const cardTypes = sortBy(useGetObjects('cardType'), 'name')

  // Handle the user clicking between different card types in the map menu
  useEffect(() => {
    if (cardTypeId && ref.current) {
      ref.current.value = cardTypeId
    }
  }, [cardTypeId])

  return (
    <SelectInput defaultValue={cardTypeId || ''} placeholder={null} name="scope.cardTypeId" ref={ref}>
      <option value="">All types</option>
      {cardTypes.map((cardType) => (
        <option key={cardType.id} value={cardType.id}>
          {cardType.name}
        </option>
      ))}
    </SelectInput>
  )
}

export default QueryExistingBasicCardFields
