import { HStack, Icon, IconButton, Progress, Spacer, Stack, Text } from '@chakra-ui/react'
import { formatDistanceToNow } from 'date-fns'
import type { FC } from 'react'
import { Link } from 'react-router-dom'

import { progressPercentForTask, progressColorForTask, linkForTask } from '@app/pages/tasks/taskHelpers'
import ICON_MAP from '@app/utils/iconMap'
import type { Task as TaskType } from '@graphql/types'
import { TaskStatusesEnum } from '@graphql/types'

interface Props {
  task: TaskType
  showLink?: boolean
}

const Task: FC<Props> = ({ task, showLink = true }) => {
  const { description, summary, status, updatedAt } = task
  const progressColor = progressColorForTask(task)
  const progressPercent = progressPercentForTask(task)
  const link = linkForTask(task)

  return (
    <Stack spacing={4}>
      <Stack spacing={0}>
        <HStack alignItems="flex-start">
          <Text fontSize="md" fontWeight="semibold">
            {description}
          </Text>
          <Spacer />
          {!!link && showLink && (
            <IconButton
              as={Link}
              aria-label="Manage task"
              colorScheme="gray"
              icon={<Icon as={ICON_MAP.Settings} />}
              size="xs"
              target="_blank"
              to={link}
              variant="ghost"
            />
          )}
        </HStack>
        <Text fontSize="xs" title={updatedAt}>
          {formatDistanceToNow(new Date(updatedAt), { includeSeconds: true })} ago
        </Text>
      </Stack>
      <Progress
        colorScheme={progressColor}
        isIndeterminate={status === TaskStatusesEnum.InProgress}
        size="sm"
        value={progressPercent}
      />
      <Text fontSize="sm">{summary}</Text>
    </Stack>
  )
}

export default Task
