import { Button, Tooltip } from '@chakra-ui/react'
import { useCallback } from 'react'
import type { OperationResult, UseMutationResponse } from 'urql'

// Base type for our mutation variables
interface MutationVariables {
  input: {
    chatId: string
  }
}

type Props<TMutation, TVariables extends MutationVariables> = {
  chatId: string
  buttonLabel: string
  onLabelChange: (label: string) => void
  isDisabled?: boolean
  mutation: UseMutationResponse<TMutation, TVariables>
  getLabelFromResponse: (response: OperationResult<TMutation, TVariables>) => string | undefined
  colorScheme?: string
}

const AssistantButton = <TMutation, TVariables extends MutationVariables>({
  chatId,
  buttonLabel,
  onLabelChange,
  isDisabled = false,
  mutation,
  getLabelFromResponse,
  colorScheme = 'green'
}: Props<TMutation, TVariables>) => {
  const [result, mutate] = mutation

  const onClick = useCallback(async () => {
    if (chatId) {
      const response = await mutate({
        input: {
          chatId
        }
      } as TVariables)
      const newLabel = getLabelFromResponse(response)
      if (newLabel) {
        onLabelChange(newLabel)
      }
    }
  }, [chatId, mutate, onLabelChange, getLabelFromResponse])

  return (
    <Tooltip aria-label={buttonLabel} label={buttonLabel}>
      <Button
        me={2}
        colorScheme={colorScheme}
        isDisabled={isDisabled || result.fetching}
        onClick={onClick}
        size="xs"
        type="button"
        variant="primary"
      >
        {buttonLabel}
      </Button>
    </Tooltip>
  )
}

export default AssistantButton
