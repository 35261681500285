import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Stack
} from '@chakra-ui/react'
import * as Sentry from '@sentry/react'
import AwsS3, { type AwsBody } from '@uppy/aws-s3'
import Uppy from '@uppy/core'
import type { UppyOptions } from '@uppy/core'
import type { FC } from 'react'
import { useRef, useState } from 'react'
import { FiUpload } from 'react-icons/fi'

import { MAX_AWS_FILE_SIZE } from '@app/lib/globals'

interface Props {
  autoProceed?: boolean
  fileTypes?: string[]
  name: string
  label?: string
  placeholder?: string
  maxFileSize?: number
  isRequired?: boolean
  onUploadStart?: (arg1?) => void
  onUploadSuccess?: (file: string, arg2?, arg3?) => void
  onUploadError?: (error: Error) => void
  errored?: boolean
  previewElement?: React.ReactNode
}

type Meta = Record<string, unknown>

const UppyFileInput: FC<Props> = ({
  autoProceed = true,
  fileTypes,
  name,
  label = '',
  placeholder = 'Choose a file to upload',
  maxFileSize = MAX_AWS_FILE_SIZE,
  isRequired = false,
  onUploadStart = (data) => data,
  onUploadSuccess = (fileData, file, response) => ({ fileData, file, response }),
  onUploadError = (error) => error,
  errored = false,
  previewElement = null
}) => {
  const [uploading, setUploading] = useState(false)
  const [fileName, setFileName] = useState('')
  const [shrineHash, setShrineHash] = useState('')
  const inputRef = useRef<HTMLInputElement>()

  const uppyOptions: UppyOptions<Meta, AwsBody> = {
    autoProceed,
    onBeforeUpload: () => {
      setUploading(true)

      return true
    },
    locale: {
      strings: { chooseFiles: placeholder },
      pluralize(_n: number): number {
        throw new Error('Function not implemented.')
      }
    },
    restrictions: {
      maxNumberOfFiles: 1,
      maxFileSize,
      allowedFileTypes: fileTypes,
      minFileSize: 0,
      maxTotalFileSize: 0,
      minNumberOfFiles: 1,
      requiredMetaFields: []
    }
  }

  const uppy = new Uppy(uppyOptions)

  uppy.use(AwsS3, {
    endpoint: '/',
    headers: {
      'x-amz-server-side-encryption': 'AES256'
    },
    shouldUseMultipart: true
  })

  uppy
    .on('file-added', (file) => {
      if (file.type.includes('image/')) {
        const { data } = file // is a Blob instance
        const url = URL.createObjectURL(data)
        const image = new Image()

        image.src = url
        image.onload = () => {
          uppy.setFileMeta(file.id, { width: image.width, height: image.height })
          URL.revokeObjectURL(url)
        }
      }

      setFileName(file.name)
      setUploading(true)
    })
    .on('upload', (data) => {
      onUploadStart(data)
    })
    .on('upload-success', (file, response) => {
      let additionalMetadata = {}

      if (file.type.includes('image/') && file.meta?.height && file.meta?.width) {
        additionalMetadata = { height: file.meta.height, width: file.meta.width }
      }

      const uploadedFileData = JSON.stringify({
        id: response.uploadURL.match(/\/cache[\w]*\/([^?]+)/)[1], // extract key without prefix
        storage: 'cache',
        metadata: {
          size: file.size,
          filename: file.name,
          mime_type: file.type,
          ...additionalMetadata
        }
      })

      onUploadSuccess(uploadedFileData, file, response)
      setUploading(false)
      setShrineHash(uploadedFileData)
    })
    .on('file-removed', () => {
      if (inputRef.current) {
        inputRef.current.value = null
        setFileName('')
        setShrineHash('')
      }
    })
    .on('complete', () => {
      inputRef.current.value = null
    })
    .on('error', (error) => {
      onUploadError(error)
    })

  return (
    <FormControl isInvalid={errored && !uploading && !fileName} isRequired={isRequired}>
      <Stack>
        {label && <FormLabel htmlFor="uppy-input">{label}</FormLabel>}
        <Stack w="full" maxW={{ md: '3xl' }} spacing={{ base: '3', md: '5' }}>
          <InputGroup maxW={{ md: '3xl' }}>
            <InputLeftElement pointerEvents="none">
              <Icon as={FiUpload} />
            </InputLeftElement>
            <input
              id="uppy-input"
              type="file"
              accept={fileTypes.toString()}
              ref={inputRef}
              style={{ display: 'none' }}
              onChange={(event) => {
                const files = Array.from(event.target.files)

                files.forEach((file) => {
                  try {
                    uppy.addFile({
                      source: 'file input',
                      name: file.name,
                      type: file.type,
                      data: file
                    })
                  } catch (err) {
                    Sentry.captureException(err)
                  }
                })
              }}
            />

            <input style={{ display: 'none' }} name={name} readOnly value={shrineHash} />

            <Input
              maxW={{ md: '3xl' }}
              disabled={uploading}
              isReadOnly
              onClick={() => !uploading && inputRef.current.click()}
              placeholder={placeholder}
              value={fileName}
            />
          </InputGroup>
          {previewElement}
        </Stack>
        <FormErrorMessage>A file is required to complete this form.</FormErrorMessage>
      </Stack>
    </FormControl>
  )
}

export default UppyFileInput
