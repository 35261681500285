import {
  Center,
  Spacer,
  Stack,
  Text,
  Menu,
  Divider,
  MenuButton,
  IconButton,
  MenuList,
  MenuItem
} from '@chakra-ui/react'
import type { FC } from 'react'
import { GrFormAdd } from 'react-icons/gr'
import { useParams, Link, useNavigate } from 'react-router-dom'

import ReportsList from './components/drawerList'
import ReportsEmpty from './components/reportsEmpty'
import RecurringReportConfigurationsList from './recurring/components/drawerList'

import useGetObjectPage from '@app/hooks/useGetObjectPage'
import useGetObjectsByProperties from '@app/hooks/useGetObjectsByProperties'
import DrawerHeader from '@app/pages/reports/components/drawerHeader'
import DrawerCloseButton from '@app/shared/drawer/drawerCloseButton'
import Pagination from '@app/shared/pagination'
import { useStore } from '@app/store'
import { ReportCreate } from '@graphql/documents/report.graphql'
import type { ReportCreateMutation } from '@graphql/queries'

const List: FC = () => {
  const { strategyId } = useParams()
  const navigate = useNavigate()

  const actionMutation = useStore.use.actionMutation()
  const addObjectToPage = useStore.use.addObjectToPage()

  const { collection, metadata } = useGetObjectPage('report')

  const recurrings = useGetObjectsByProperties('recurringReportConfiguration', {
    strategyId
  })

  if ((!collection || collection.length === 0) && (!recurrings || recurrings.length === 0)) {
    return <ReportsEmpty strategyId={strategyId} />
  }

  const createReport = async () => {
    // Generate a date field like month-day-year I can then interpolate into the report name

    const date = new Date()
    const formattedDate = `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`

    const resp = await actionMutation<ReportCreateMutation>(ReportCreate, {
      name: `New report - ${formattedDate}`,
      strategyId
    })

    if (resp) {
      const report = resp?.data?.reportCreate?.report

      if (report) {
        addObjectToPage(report, 0)
      }

      navigate(`/strategy/${strategyId}/map/reports/${report?.id}`)
    }
  }

  return (
    <Stack id="report-drawer">
      <DrawerHeader px={4}>
        <Text variant="section-header">Reports</Text>
        <Spacer />
        <Menu>
          <MenuButton
            as={IconButton}
            fontSize="sm"
            aria-label="Create new report"
            icon={<GrFormAdd />}
            variant="tertiary"
          />
          <MenuList>
            <MenuItem onClick={createReport}>Create a one-time summary</MenuItem>
            <MenuItem>
              <Link to="recurring/create">Create recurring report</Link>
            </MenuItem>
          </MenuList>
          <DrawerCloseButton />
        </Menu>
      </DrawerHeader>
      {recurrings.length > 0 && <RecurringReportConfigurationsList recurringReportConfigurations={recurrings} />}
      <ReportsList reports={collection} />
      <Divider />
      <Center>
        <Pagination px={0} py={4} {...{ ...metadata }} scrollContainer="report-drawer" simple />
      </Center>
    </Stack>
  )
}

export default List
