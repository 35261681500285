import isEmpty from 'lodash/isEmpty'
import { useEffect, useState } from 'react'
import { useActionData, useNavigation } from 'react-router-dom'

import type { Error } from '@graphql/queries'

const GLOBAL_ERROR = 'We were unable to save your data, please contact us for help!'

interface ActionData {
  success?: boolean
  error?: Array<Error>
}

export interface Errors {
  [key: string]: {
    message: string
    error?: never
  }
}

export interface Props {
  onError?: (errors: Errors) => void
  onSuccess?: (data: ActionData) => void
  globalError?: string
}

type UseForm = (props: Props) => {
  errors: Errors
  data: ActionData
  reset: () => void
}
const useForm: UseForm = (props) => {
  const { onError = () => {}, onSuccess = () => {}, globalError = GLOBAL_ERROR } = props

  const actionData: ActionData = useActionData()
  const navigation = useNavigation()
  const [formErrors, setFormErrors] = useState({})

  useEffect(() => {
    const errors: Errors = {}

    if (actionData?.error?.length) {
      actionData.error.forEach((error) => {
        if (error.path?.length) {
          let field: string[] | string = error.path.slice(-1)
          if (Array.isArray(field)) {
            ;[field] = field
          }

          errors[field] = { message: error.message }
        } else {
          errors.global = { message: globalError }
        }
      })

      setFormErrors(errors)
      onError(errors)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
    } else if (actionData?.error?.error) {
      errors.global = { message: globalError }
      setFormErrors(errors)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionData, actionData?.error, globalError])

  const isReloading =
    navigation.state === 'loading' &&
    navigation.formData != null &&
    navigation.formAction === navigation.location.pathname

  // Are we redirecting after an action?
  const isRedirecting =
    navigation.state === 'loading' &&
    navigation.formData != null &&
    navigation.formAction !== navigation.location.pathname

  if (isReloading || isRedirecting) {
    if (actionData && isEmpty(actionData?.error)) {
      onSuccess(actionData)
    }
  }

  const reset = () => {
    setFormErrors({})
  }

  return { errors: formErrors, data: actionData, reset }
}

export default useForm
