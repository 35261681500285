import { Box, HStack, Icon, Spinner, Text } from '@chakra-ui/react'
import type { UppyFile } from '@uppy/core'
import truncate from 'lodash/truncate'
import type { FC } from 'react'
import { PiFile, PiFileDoc, PiFileHtml, PiFileJs, PiFileMd, PiFilePdf, PiFilePy } from 'react-icons/pi'

import type { FileTicketMeta, RemoteFile } from '@app/shared/uploader/types'

interface Props {
  file: UppyFile<FileTicketMeta, RemoteFile>
}

const File: FC<Props> = ({ file }) => {
  let icon = PiFile
  const { percentage } = file.progress

  if (file.extension === 'pdf') {
    icon = PiFilePdf
  } else if (file.extension === 'doc' || file.extension === 'docx') {
    icon = PiFileDoc
  } else if (file.extension === 'html') {
    icon = PiFileHtml
  } else if (file.extension === 'js' || file.extension === 'json' || file.extension === 'ts') {
    icon = PiFileJs
  } else if (file.extension === 'md') {
    icon = PiFileMd
  } else if (file.extension === 'py') {
    icon = PiFilePy
  }

  return (
    <HStack
      key={file.toString()}
      border="border.default"
      borderWidth={1}
      borderRadius="md"
      bgColor="bg.surface"
      spacing={0}
    >
      <Box h="100%" px={2} pt={1} textAlign="center" borderRadius="md" borderRightRadius={0} bgColor="blue.700">
        {percentage > 0 && percentage < 100 ? (
          <Spinner boxSize={4} color="white" />
        ) : (
          <Icon as={icon} boxSize={4} color="white" />
        )}
      </Box>
      <Text w="100%" px={2} color="fg.subtle" fontSize="xs" title={file.name}>
        {truncate(file.name, { length: 20 })}
      </Text>
    </HStack>
  )
}

export default File
