import { forwardRef, Textarea } from '@chakra-ui/react'
import { useEffect } from 'react'
import type { RefObject } from 'react'

const ChatTextarea = forwardRef(({ onInput, ...rest }, ref: RefObject<HTMLTextAreaElement>) => {
  const resizeTextBox = (event) => {
    const textarea = event.currentTarget
    textarea.style.height = 'auto'
    const borderHeight = textarea.offsetHeight - textarea.clientHeight
    textarea.style.height = `${textarea.scrollHeight + borderHeight}px`
    onInput?.(event)
  }

  useEffect(() => {
    if (rest.isDisabled && ref?.current) {
      resizeTextBox({ currentTarget: ref.current })
    }
  }, [rest.isDisabled, ref])

  return (
    <Textarea
      ref={ref}
      maxH="200px"
      pe="9"
      _placeholder={{ color: 'fg.subtle' }}
      resize="none"
      autoFocus
      name="prompt"
      onInput={resizeTextBox}
      onKeyDown={(e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
          e.preventDefault()
          e.currentTarget.form.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
        }
      }}
      placeholder="Ask me anything..."
      rows={3}
      {...rest}
    />
  )
})

export default ChatTextarea
