import type { BoxProps } from '@chakra-ui/react'
import { Stack } from '@chakra-ui/react'
import { useViewport } from '@xyflow/react'
import type { FC } from 'react'
import { memo } from 'react'

import { Card, CardDueDate } from '../components'
import CardLabels from '../components/cardLabels'
import { CardProvider, useCardContext } from '../contexts/cardContext'

import useIsAnonymousUser from '@app/hooks/useIsAnonymousUser'
import { aiInsightsShadow } from '@app/lib/globals'
import WithLabel from '@app/next/forms/withLabel'
import CollapsedNodes from '@app/pages/maps/components/nodes/components/collapsedNodes'
import { scaleSizeToMapZoom } from '@app/pages/maps/components/nodes/helpers'
import cardFontSize from '@app/shared/cards/cardFontSize'
import CardDetailsLink from '@app/shared/cards/components/cardDetailsLink'
import CardSection from '@app/shared/cards/components/cardSection'
import CardSharedActions from '@app/shared/cards/components/cardSharedActions'
import CardTitle from '@app/shared/cards/components/cardTitle'
import CardTypeSection from '@app/shared/cards/components/cardTypeSection'
import CardEntityContributorsAndStatus, {
  shouldRenderCardEntityContributorsAndStatus
} from '@app/shared/cards/entity/cardEntityContributorsAndStatus'
import CardEntityStatsAndProgress, {
  shouldRenderCardEntityStatsAndProgress
} from '@app/shared/cards/entity/cardEntityStatsAndProgress'
import CardEntityStatus from '@app/shared/cards/entity/cardEntityStatus'
import MarkdownDisplay from '@app/shared/markdownDisplay'
import PulsingCircle from '@app/shared/pulsingCircle'
import { withBasicProvider } from '@app/shared/utils/withProviders'
import type { MapDomainEntity, MapDomainNode } from '@app/types'
import ICON_MAP, { COLOR_MAP } from '@app/utils/iconMap'

interface Props extends BoxProps {
  strategyId: string
  entity?: MapDomainEntity
  node?: MapDomainNode
  onClick?: () => void
  onDoubleClick?: () => void
}

export const EntityCard: FC<Props> = ({ strategyId, entity, node, ...rest }) => {
  const isAnonymousUser = useIsAnonymousUser(strategyId)
  const { size } = useCardContext()
  const { zoom } = useViewport()
  const {
    aiGenerated,
    dueDate,
    foreignState,
    goalHypothesis,
    id,
    labels,
    linkTitle,
    linkUrl,
    recentActivity,
    results,
    showGoalHypothesis,
    showResults,
    showSummary,
    sourceName,
    summary,
    workflowState
  } = entity

  const route = isAnonymousUser ? null : `/strategy/${strategyId}/map/entity/${id}`

  const pulseSize = scaleSizeToMapZoom(24, zoom, 44)
  const fontSize = cardFontSize('sm', size)

  const boxProps: BoxProps = {}
  if (aiGenerated) {
    boxProps.boxShadow = aiInsightsShadow
  }

  return (
    <Card {...boxProps} {...rest}>
      <input type="hidden" name="sourceName" value={sourceName || 'web'} />
      <CardTypeSection title="Work" icon={ICON_MAP.Work} color={COLOR_MAP.Entity} route={route} size={size} />
      <CardSection size={size}>
        <PulsingCircle isActive={!!recentActivity} size={pulseSize} link={`entity/${id}/events`} />
        <Stack spacing={size === 'large' ? 6 : 2}>
          <CardTitle domainObject={entity} name="name" size={size} />
          {showSummary && summary && <MarkdownDisplay text={summary} />}
          {showGoalHypothesis && goalHypothesis && (
            <WithLabel showLabel={!!goalHypothesis} label="Goal / Hypothesis">
              <MarkdownDisplay text={goalHypothesis} fontSize={fontSize} />
            </WithLabel>
          )}
          {showResults && results && (
            <WithLabel showLabel={!!results} label="Results">
              <MarkdownDisplay text={results} />
            </WithLabel>
          )}
          {linkUrl && <CardDetailsLink url={linkUrl} text={linkTitle} size={size} />}
        </Stack>
      </CardSection>
      {shouldRenderCardEntityStatsAndProgress(entity) && (
        <CardSection size={size}>
          <CardEntityStatsAndProgress entity={entity} size={size} />
        </CardSection>
      )}

      {shouldRenderCardEntityContributorsAndStatus(entity) && (
        <CardSection size={size}>
          <CardEntityContributorsAndStatus entity={entity} size={size} />
        </CardSection>
      )}
      {dueDate && (
        <CardSection size={size}>
          <CardDueDate domainObject={entity} fieldName="dueDate" size={size} />
        </CardSection>
      )}
      {labels?.length && <CardLabels fieldName="labels" domainObject={entity} size={size} />}
      <CardSharedActions domainObject={entity} size={size} route={route}>
        <CardEntityStatus status={foreignState} workflowState={workflowState} size={size} />
      </CardSharedActions>
      {node && <CollapsedNodes node={node} />}
    </Card>
  )
}

export const EntityCardWithContext = memo(withBasicProvider(CardProvider)(EntityCard))
