import { Button, Icon, Link, useDisclosure } from '@chakra-ui/react'
import type { FC, MutableRefObject } from 'react'
import { useImperativeHandle } from 'react'
import { PiArrowSquareOut } from 'react-icons/pi'
import { SiGooglesheets } from 'react-icons/si'
import { useLocation } from 'react-router-dom'

import useGetObject from '@app/hooks/useGetObject'
import GoogleSheetsConnectModal from '@app/pages/metrics/components/googleSheetsConnectModal'
import type { MetricSourceFormApi } from '@app/types'
import type { MetricSource } from '@graphql/types'

export interface Props {
  metricSource: Pick<MetricSource, 'id' | 'configuration' | 'googleSheet'> | null
  isDisabled: boolean
  apiRef: MutableRefObject<MetricSourceFormApi>
  metricId: string
}

const GoogleSheets: FC<Props> = ({ apiRef, metricSource, isDisabled, metricId }) => {
  const location = useLocation()

  const disclosure = useDisclosure()

  const googleSheetId = metricSource?.googleSheet?.id
  const googleSheet = useGetObject(googleSheetId, 'googleSheet')

  useImperativeHandle(
    apiRef,
    () => ({
      isValid: () => !!googleSheet?.id
    }),
    [googleSheet?.id]
  )

  const modalState = {
    redirect_to: location.pathname,
    metric_id: metricId,
    type: 'source' as const
  }

  const onClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
    disclosure.onOpen()
  }

  return (
    <>
      <GoogleSheetsConnectModal {...disclosure} state={modalState} />
      {googleSheet && (
        <Button as={Link} href={googleSheet?.spreadsheetUrl} isExternal rightIcon={<PiArrowSquareOut />}>
          Open Google Sheet
        </Button>
      )}
      {!googleSheet && (
        <Button isDisabled={isDisabled} leftIcon={<Icon as={SiGooglesheets} />} onClick={onClick} type="button">
          Connect
        </Button>
      )}
    </>
  )
}

export default GoogleSheets
