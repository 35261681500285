import { HStack } from '@chakra-ui/react'
import type { FC, ReactNode } from 'react'

import DrawerCloseButton from '@app/shared/drawer/drawerCloseButton'
import GoToNodeButton from '@app/shared/drawer/goToNodeButton'

interface Props {
  objId: string
  objType: string
  children?: ReactNode
  strategyId: string | null
}

const DrawerHeaderActions: FC<Props> = ({ objId, objType, children, strategyId }) => {
  if (!strategyId) {
    return null
  }

  return (
    <HStack pr={4} spacing={0}>
      {children}
      <GoToNodeButton objId={objId} objType={objType} />
      <DrawerCloseButton variant="ghost" />
    </HStack>
  )
}

export default DrawerHeaderActions
