import type { ButtonProps } from '@chakra-ui/react'
import { Button, HStack, Spacer, Stack, VisuallyHiddenInput } from '@chakra-ui/react'
import type { FC, FormEventHandler, MouseEventHandler } from 'react'
import { useEffect, useCallback, useRef, useState } from 'react'
import { Outlet, useParams, useRevalidator } from 'react-router-dom'

import CredentialInput from './components/credentialInput'
import ConfigureMetricSource from './configureMetricSource'
import MetricSourceDeleteButton from './metricSourceDeleteButton'
import MetricSourceRunButton from './metricSourceRunButton'
import TestMetricSourceButton from './testMetricSourceButton'

import useGetObjectsByProperties from '@app/hooks/useGetObjectsByProperties'
import MetricSourceTasks from '@app/pages/metrics/components/metricSource/metricSourceTasks'
import { createMetricSource, updateMetricSource } from '@app/pages/metrics/components/metricSource/utils'
import { RawForm } from '@app/shared/rawForms/form'
import useToast from '@app/shared/toast'
import type { MetricSourceFormApi } from '@app/types'
import type { MetricSourceQuery } from '@graphql/queries'

type MetricSource = Omit<MetricSourceQuery['metricSource'], 'metric'> & {
  metric: Pick<MetricSourceQuery['metricSource']['metric'], 'id' | 'name'>
}

type EditingProps = ButtonProps & {
  metricSource: MetricSource | null
}

type ViewingProps = {
  onEditClick: MouseEventHandler<HTMLButtonElement>
  metricSource: MetricSource | null
  onDelete: () => void
}

export type CredentialSelectApi = {
  clear: () => void
  reset: () => void
}

const Editing: FC<EditingProps> = ({ metricSource, ...rest }) => (
  <HStack>
    <Spacer />
    {metricSource && (
      <Button type="reset" variant="secondary">
        Discard changes
      </Button>
    )}
    <Button type="submit" variant="primary" {...rest}>
      Save changes
    </Button>
  </HStack>
)

const Viewing: FC<ViewingProps> = ({ onDelete: propOnDelete, metricSource, onEditClick }) => {
  const { revalidate } = useRevalidator()
  const onDelete = () => {
    propOnDelete()
    revalidate()
  }

  return (
    <HStack>
      {metricSource && <MetricSourceDeleteButton metricSourceId={metricSource.id} onDelete={onDelete} />}
      <Spacer />
      <Button onClick={onEditClick} variant="secondary">
        Edit
      </Button>
      <TestMetricSourceButton metricSource={metricSource} variant="secondary" />
      <MetricSourceRunButton metricSource={metricSource} />
    </HStack>
  )
}

const MetricSourceShow = () => {
  const { nodeId: metricId } = useParams()
  const filter = useCallback((ms: MetricSource) => ms.metric?.id === metricId, [metricId])
  const metricSource = useGetObjectsByProperties('metricSource', filter)[0]
  const defaultCredential = metricSource?.credential
  const defaultSourceName = defaultCredential?.sourceName || metricSource?.sourceName
  const [credential, setCredential] = useState(defaultCredential)
  const [sourceName, setSourceName] = useState(defaultSourceName)
  const [editing, setEditing] = useState(!metricSource)
  const [isDisabled, setIsDisabled] = useState(false)
  const configureSourceRef = useRef<MetricSourceFormApi>(null)
  const credentialSelectRef = useRef<CredentialSelectApi>(null)
  const toast = useToast()

  // This is not nearly comprehensive on actually resetting the forms when navigating between
  // metric sources. It will update between metric sources of the Amplitude type but not between types
  // This satisfies the first direct part of the issue with a customer but this is going to persist as an issue.
  // Couple areas of complexity that seem to be driving some of the primary challenges:
  // The nature of using all the ref's to hold values and reset them
  // The Credential Input is both setting the "Source" as well as an optional Credential record if appropriate.
  useEffect(() => {
    if (metricSource) {
      setCredential(metricSource.credential)
      setSourceName(metricSource.credential?.sourceName || metricSource.sourceName)
      setEditing(!metricSource)
      credentialSelectRef.current?.reset?.()
      configureSourceRef.current?.reset?.()
    }
  }, [metricSource])

  const onEditCancel = () => {
    configureSourceRef.current?.reset?.()
    setEditing(false)
    setSourceName(defaultSourceName)
    setCredential(defaultCredential)
  }

  const onDelete = () => {
    configureSourceRef.current?.clear?.()
    credentialSelectRef.current?.clear()
    setEditing(true)
    setSourceName('')
    setCredential(null)
  }

  const onSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault()

    try {
      setIsDisabled(true)
      const formData = new FormData(e.currentTarget)
      const method = metricSource ? updateMetricSource : createMetricSource

      await method({ metricId, formData })
      setEditing(false)
    } catch (err) {
      toast({
        title: 'Error saving metric source',
        description: `There was an error saving this metric source: ${err.error.message}.`,
        status: 'error'
      })
    } finally {
      setIsDisabled(false)
    }
  }

  const invalidConfig = configureSourceRef.current?.isValid?.() === false

  return (
    <Stack p={4} spacing={4}>
      <RawForm onSubmit={onSubmit} onReset={onEditCancel} variant="drawer">
        {metricSource && <VisuallyHiddenInput defaultValue={metricSource.id} name="metricSourceId" />}
        <Stack spacing={4}>
          <HStack>
            <CredentialInput
              credential={credential}
              defaultSourceName={sourceName}
              width="100%"
              isDisabled={isDisabled || !!metricSource}
              onSelect={(newSourceName, newCredential) => {
                setSourceName(newSourceName)
                setCredential(newCredential)
              }}
              apiRef={credentialSelectRef}
            />
          </HStack>
          <ConfigureMetricSource
            apiRef={configureSourceRef}
            metricSource={metricSource}
            sourceName={sourceName}
            isDisabled={isDisabled || !editing}
            credentialId={credential?.id}
            metricId={metricId}
          />
          {editing ? (
            <Editing metricSource={metricSource} isDisabled={isDisabled || !sourceName || invalidConfig} />
          ) : (
            <Viewing metricSource={metricSource} onEditClick={() => setEditing(true)} onDelete={onDelete} />
          )}
        </Stack>
      </RawForm>
      <Outlet />
      <MetricSourceTasks metricSource={metricSource} />
    </Stack>
  )
}

export default MetricSourceShow
