import { useColorModeValue } from '@chakra-ui/react'
import AwsS3, { type AwsBody } from '@uppy/aws-s3'
import type { UppyOptions } from '@uppy/core'
import Uppy from '@uppy/core'
import { Dashboard, FileInput } from '@uppy/react'
import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import '@uppy/file-input/dist/style.css'
import type { FC } from 'react'

import type { OnUploadSuccess } from '@app/types'

type Meta = Record<string, unknown>

type Props = {
  uppyOptions?: UppyOptions<Meta, AwsBody>
  onUploadSuccess?: OnUploadSuccess
  fileInput?: boolean
  inputName?: string
}

const AwsUploader: FC<Props> = ({
  uppyOptions = {},
  onUploadSuccess = () => {},
  fileInput = false,
  inputName = undefined
}) => {
  const theme = useColorModeValue('light', 'dark')
  const uppy = new Uppy(uppyOptions)

  uppy.use(AwsS3, {
    endpoint: '/',
    headers: {
      'x-amz-server-side-encryption': 'AES256'
    },
    shouldUseMultipart: true
  })

  uppy
    .on('file-added', (file) => {
      if (file?.type?.includes('image/')) {
        const { data } = file // is a Blob instance
        const url = URL.createObjectURL(data)
        const image = new Image()
        image.src = url
        image.onload = () => {
          uppy.setFileMeta(file.id, { width: image.width, height: image.height })
          URL.revokeObjectURL(url)
        }
      }
    })
    .on('upload-success', (file, response) => {
      let additionalMetadata = {}

      if (!file || !response?.uploadURL) {
        return
      }

      if (file.type?.includes('image/') && file.meta?.height && file.meta?.width) {
        additionalMetadata = { height: file.meta.height, width: file.meta.width }
      }

      const uploadedFileData = JSON.stringify({
        id: response.uploadURL.match(/\/cache\w*\/([^?]+)/)?.[1], // extract key without prefix
        storage: 'cache',
        metadata: {
          size: file.size,
          filename: file.name,
          mime_type: file.type,
          ...additionalMetadata
        }
      })

      onUploadSuccess(uploadedFileData, file, response)
    })

  if (fileInput) {
    return <FileInput uppy={uppy} inputName={inputName} />
  }

  return <Dashboard uppy={uppy} theme={theme} proudlyDisplayPoweredByUppy={false} />
}

export default AwsUploader
