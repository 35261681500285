import type { FC, MutableRefObject } from 'react'
import { useImperativeHandle, useState } from 'react'

import EventNamesInput from './eventNamesInput'

import ChartInput from '@app/pages/metrics/components/metricSource/amplitude/chartInput'
import { SelectInput } from '@app/shared/rawForms'
import type { MetricSourceFormApi } from '@app/types'
import type { MetricSource } from '@graphql/types'
import { AmplitudeRegionEnum } from '@graphql/types'

export type Props = {
  metricSource: Pick<MetricSource, 'configuration'> | null
  credentialId: string
  isDisabled: boolean
  apiRef: MutableRefObject<MetricSourceFormApi>
}

enum DataSourceEnum {
  Event = 'event',
  Chart = 'chart'
}

const Amplitude: FC<Props> = ({ apiRef, isDisabled, credentialId, metricSource }) => {
  const defaultDataSource = metricSource?.configuration?.data_source || DataSourceEnum.Chart
  const [queryType, setQueryType] = useState<DataSourceEnum>(defaultDataSource)
  const defaultChart = metricSource?.configuration?.chart_url || metricSource?.configuration?.chart_id
  const [chart, setChart] = useState<string>(defaultChart)

  useImperativeHandle(
    apiRef,
    () => ({
      reset: () => {
        setQueryType(defaultDataSource)
        setChart(defaultChart)
      },
      clear: () => {
        setQueryType(DataSourceEnum.Chart)
        setChart('')
      }
    }),
    [defaultChart, defaultDataSource]
  )

  return (
    <>
      <SelectInput
        name="configuration.data_source"
        data-testid="amplitude-configuration-data-source"
        label="Query type"
        placeholder={null}
        defaultValue={queryType}
        isDisabled={isDisabled}
        onChange={(e) => setQueryType(e.target.value as DataSourceEnum)}
      >
        <option value="event">Event</option>
        <option value="chart">Chart</option>
      </SelectInput>
      {queryType === DataSourceEnum.Event && (
        <EventNamesInput
          name="configuration.event"
          label="Event name"
          placeholder=""
          isDisabled={isDisabled}
          isRequired
          defaultValue={metricSource?.configuration?.event}
          credentialId={credentialId}
          region={metricSource?.configuration?.region || AmplitudeRegionEnum.Us}
        />
      )}
      {queryType === DataSourceEnum.Chart && (
        <ChartInput
          chart={chart}
          label="Chart URL"
          placeholder="Amplitude chart URL"
          isDisabled={isDisabled}
          isRequired
          onChange={(e) => setChart(e.target.value)}
        />
      )}
      <SelectInput
        name="configuration.region"
        label="Region"
        placeholder={null}
        defaultValue={metricSource?.configuration?.region || AmplitudeRegionEnum.Us}
        isDisabled={isDisabled}
      >
        <option value="us">US</option>
        <option value="eu">EU</option>
      </SelectInput>
    </>
  )
}

export default Amplitude
