import { Box } from '@chakra-ui/react'
import type { FC } from 'react'

import AggregatedEntities from './aggregatedEntities'

import GroupedEntities from '@app/shared/entities/groupedEntities'
import Pagination from '@app/shared/pagination'
import type { Nodes } from '@app/types'
import type { Entity } from '@graphql/queries'

interface Props {
  collection: Entity[]
  metadata: object
  layout?: 'roadmap' | 'changelog'
  nodeType: Nodes
}

const Entities: FC<Props> = ({ collection, metadata, nodeType, layout = 'changelog', ...rest }) => (
  <Box id="lazyload-container" {...rest}>
    {layout === 'changelog' ? (
      <AggregatedEntities entities={collection} variant="drawer" nodeType={nodeType} />
    ) : (
      <GroupedEntities entities={collection} nodeType={nodeType} />
    )}

    <Pagination pt={8} pb={4} {...{ ...metadata }} />
  </Box>
)

export default Entities
