import { Divider, Stack, StackDivider } from '@chakra-ui/react'
import type { FC } from 'react'
import { useState } from 'react'

import TasksFetcher from '@app/fetchers/tasksFetcher'
import useGetObjectsByProperties from '@app/hooks/useGetObjectsByProperties'
import MetricSourceImportStatus from '@app/pages/metrics/components/metricSource/metricSourceImportStatus'
import Task from '@app/pages/tasks/components/task'
import type { MetricSource, Task as TaskType } from '@graphql/types'

interface Props {
  metricSource: Pick<
    MetricSource,
    'id' | 'name' | 'errorMessage' | 'metricId' | 'erroredAt' | 'lastRunAt' | 'scheduled'
  >
  limit?: number
}

const MetricSourceTasks: FC<Props> = ({ metricSource, limit = 5 }) => {
  const [isLoading, setIsLoading] = useState(false)

  const taskSort = (a: TaskType, b: TaskType) => {
    const d1 = new Date(a.createdAt).getTime()
    const d2 = new Date(b.createdAt).getTime()

    return d2 - d1
  }

  const tasks = useGetObjectsByProperties(
    'task',
    (task) => task.domainObjectType === 'Metric' && task.domainObjectId === metricSource?.metricId
  )
    .sort(taskSort)
    .slice(0, limit)

  if (!metricSource) {
    return null
  }

  return (
    <>
      <TasksFetcher
        domainObjectType="Metric"
        domainObjectId={metricSource?.metricId}
        limit={limit}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
      />
      <Divider />
      <Stack spacing={4}>
        {metricSource && <MetricSourceImportStatus metricSource={metricSource} />}
        {tasks.length > 0 && (
          <Stack p={4} borderColor="border.default" borderRadius="md" bgColor="bg.subtle" divider={<StackDivider />}>
            {tasks.map((task) => (
              <Task key={task.id} task={task} showLink={false} />
            ))}
          </Stack>
        )}
      </Stack>
    </>
  )
}

export default MetricSourceTasks
