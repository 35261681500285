import type { FC, MutableRefObject, ReactNode } from 'react'
import { createContext, useContext, useMemo, useRef } from 'react'

import type { CardSize } from '@app/types'

type CardContextType = {
  submitRef: MutableRefObject<undefined>
  size: CardSize
  color: string
}

const CardContext = createContext<CardContextType>(undefined)

interface Props {
  // This feels really weird. It's a ReactNode which *should* have the concept of props?
  children: ReactNode & { props: { data: { size?: CardSize; color?: string } } }
}

const CardProvider: FC<Props> = ({ children }) => {
  const { data } = children.props
  let size: CardSize = 'normal'
  let color = null

  if (data) {
    size = data?.size || 'normal'
    color = data?.color
  }

  const submitRef = useRef()

  const value = useMemo(() => ({ submitRef, size, color }), [submitRef, size, color])

  return <CardContext.Provider value={value}>{children}</CardContext.Provider>
}

const useCardContext = () => {
  const context = useContext(CardContext)

  if (context === undefined) {
    throw new Error('useCardContext must be used within CardContext.Provider')
  }

  return context
}

export { CardProvider, useCardContext }
