import { getIncomers, getOutgoers, type Node } from '@xyflow/react'
import capitalize from 'lodash/capitalize'
import isEmpty from 'lodash/isEmpty'

import type { MapDomainEdge, MapDomainNode } from '@app/types'

type IdAndTypeToRfId = (nodeId: string, nodeType: string) => string
export const idAndTypeToRfId: IdAndTypeToRfId = (nodeId, nodeType) => {
  const nodePrefix = nodeType === 'basicCard' ? 'basicCard' : nodeType[0]

  return `${nodePrefix}${nodeId}`
}

export const scaleSizeToMapZoom = (baseSize: number, zoom: number, maxSize: number | null = null) => {
  let size = baseSize / zoom
  if (maxSize && size > maxSize) {
    size = maxSize
  }

  return `${size}px`
}

export const getNodePositionInsideParent = (node: Partial<Node>, groupNode: Node) => {
  const position = isEmpty(node.position) ? { x: 0, y: 0 } : { ...node.position }
  const nodeWidth = node.measured?.width ?? 0
  const nodeHeight = node.measured?.height ?? 0
  const groupWidth = groupNode.measured?.width ?? 0
  const groupHeight = groupNode.measured?.height ?? 0

  const newPosition = { ...position }

  if (newPosition.x < groupNode.position.x) {
    newPosition.x = 0
  } else if (newPosition.x + nodeWidth > groupNode.position.x + groupWidth) {
    newPosition.x = groupWidth - nodeWidth
  } else {
    newPosition.x -= groupNode.position.x
  }

  if (newPosition.y < groupNode.position.y) {
    newPosition.y = 0
  } else if (newPosition.y + nodeHeight > groupNode.position.y + groupHeight) {
    newPosition.y = groupHeight - nodeHeight
  } else {
    newPosition.y -= groupNode.position.y
  }

  return newPosition
}

type AttachNodeToParent = (
  node: MapDomainNode,
  parent: MapDomainNode
) => MapDomainNode & { parentNodeId: string | null }
export const attachNodeToParent: AttachNodeToParent = (node, parent) => ({
  ...node,
  position: getNodePositionInsideParent(node, parent),
  parentNodeId: parent.nodeId,
  parentId: parent.id
})

export const detachNodeFromParent = (
  node: MapDomainNode,
  parentNode: MapDomainNode
): MapDomainNode & { parentNodeId?: string } => {
  const offsets = { x: parentNode?.position?.x, y: parentNode?.position?.y }

  return {
    ...node,
    position: {
      x: node.position.x + offsets.x,
      y: node.position.y + offsets.y
    },
    parentNodeId: null,
    parentId: null
  }
}

export const getAllIncomers = (
  node: MapDomainNode,
  nodes: MapDomainNode[],
  edges: MapDomainEdge[],
  prevIncomerIds: string[] = []
): MapDomainNode[] => {
  const incomers = getIncomers(node, nodes, edges) as MapDomainNode[]

  return incomers.reduce((memo, incomer) => {
    memo.push(incomer)

    if (!prevIncomerIds.includes(incomer.id)) {
      prevIncomerIds.push(incomer.id)

      getAllIncomers(incomer, nodes, edges, prevIncomerIds).forEach((foundNode) => {
        if (!memo.map((i) => i.id).includes(foundNode.id)) {
          memo.push(foundNode)
        }

        if (!prevIncomerIds.includes(foundNode.id)) {
          prevIncomerIds.push(foundNode.id)
        }
      })
    }

    return memo
  }, [])
}

export const getAllOutgoers = (
  node: Node,
  nodes: MapDomainNode[],
  edges: MapDomainEdge[],
  prevOutgoerIds: string[] = []
): MapDomainNode[] => {
  const outgoers = getOutgoers(node, nodes, edges)

  return outgoers.reduce((memo, outgoer) => {
    memo.push(outgoer)

    if (!prevOutgoerIds.includes(outgoer.id)) {
      prevOutgoerIds.push(outgoer.id)

      getAllOutgoers(outgoer, nodes, edges, prevOutgoerIds).forEach((foundNode) => {
        if (!memo.map((i) => i.id).includes(foundNode.id)) {
          memo.push(foundNode)
        }

        if (!prevOutgoerIds.includes(foundNode.id)) {
          prevOutgoerIds.push(foundNode.id)
        }
      })
    }

    return memo
  }, [])
}

export const getNodeChildren = (node: Node, nodes: MapDomainNode[]) => {
  if (node.type !== 'section') {
    return []
  }

  return nodes.filter((n) => n.parentId === node.id)
}

export const displayNameAndType = (domainObject) => {
  let name
  let type = capitalize(domainObject.classType)
  switch (domainObject.classType) {
    case 'basicCard':
      name = domainObject?.name
      type = domainObject?.cardType?.name || null
      break
    case 'entity':
      name = domainObject?.name
      type = 'work'
      break
    default:
      name = domainObject?.name || `Unnamed ${type}`
  }
  return { name, type }
}

export const hasLoop = (node: MapDomainNode, nodes: MapDomainNode[], edges: MapDomainEdge[]) => {
  const incomers = getAllIncomers(node, nodes, edges)
  const outgoers = getAllOutgoers(node, nodes, edges)

  return incomers.some((incomer) => outgoers.some((outgoer) => incomer.id === outgoer.id))
}

// Little work in progress API. The idea of building up an array to act on so we don't feel driven to do single updates to collect related behaviors
export const queueChildrenNodesForDeletion = (node, allNodes, allEdges, nodesToDelete = []) => {
  const incomers = getIncomers(node, allNodes, allEdges).filter((n) => n.hidden && n.metadata?.collapsed)

  return [...nodesToDelete, ...incomers]
}
