import { useMemo } from 'react'
import type { FC } from 'react'

import { buildMetricSources } from '@app/pages/maps/components/addCards/helpers'
import SourceNameInput from '@app/pages/maps/components/addCards/queryExisting/sourceNameInput'
import type { SourcesMetricSource } from '@graphql/types'

type Props = {
  sources: SourcesMetricSource[]
  type: string
}

const QueryExistingMetricFields: FC<Props> = ({ sources, type }) => {
  const inputSources = useMemo(() => buildMetricSources(sources), [sources])

  return <SourceNameInput value={type} typeValue="metric" sources={inputSources} />
}

export default QueryExistingMetricFields
