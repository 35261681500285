import { useAbility } from '@casl/react'
import {
  HStack,
  Icon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
  Tooltip,
  useDisclosure
} from '@chakra-ui/react'
import type { FC } from 'react'
import { useRef } from 'react'
import { PiCaretDown } from 'react-icons/pi'

import { AbilityContext } from '@app/shared/authorization/can'
import cardFontSize from '@app/shared/cards/cardFontSize'
import { SearchList, SearchListItem } from '@app/shared/searchList'
import { useStore } from '@app/store'
import type { CardSize, MapDomainMetric } from '@app/types'
import { RollUpEnum } from '@graphql/queries'

const rollupDisplayName = (type: RollUpEnum) => {
  let displayType
  let tooltipText

  switch (type) {
    case RollUpEnum.Sum:
      displayType = 'Sum'
      tooltipText = displayType
      break
    case RollUpEnum.LastMonthAvailable:
      displayType = 'LMA'
      tooltipText = 'MoM, month over 3-month average, YoY'
      break
    case RollUpEnum.Average:
      displayType = 'Average'
      tooltipText = displayType
      break
    case RollUpEnum.Increase:
      displayType = 'Amount increased'
      tooltipText = displayType
      break
    default:
      displayType = ''
      tooltipText = displayType
  }

  return [displayType, tooltipText]
}

interface Props {
  metric: MapDomainMetric
  size?: CardSize
}

const CardRollupType: FC<Props> = ({ metric, size = 'normal' }) => {
  const ability = useAbility(AbilityContext)
  const type = metric?.rollUp
  const [displayType, tooltipText] = rollupDisplayName(type)
  const initialFocusRef = useRef()
  const { isOpen, onToggle, onClose } = useDisclosure()
  const updateObject = useStore.use.updateObject()
  const fontSize = cardFontSize('sm', size)

  if (metric?.calculated) {
    return null
  }

  const view = (
    <Tooltip aria-label="Roll up" label={tooltipText}>
      <Text mx={2} color="fg.subtle" fontSize={fontSize}>
        {displayType}
      </Text>
    </Tooltip>
  )

  if (!ability.can('update', 'metric')) {
    return view
  }

  const onChange = (value: RollUpEnum) => {
    updateObject({ metric: { id: metric.id, rollUp: value } })

    onToggle()
  }

  return (
    <Popover initialFocusRef={initialFocusRef} isLazy isOpen={isOpen} onClose={onClose}>
      <PopoverTrigger>
        <HStack cursor="pointer" onClick={onToggle}>
          {view}
          <Icon as={PiCaretDown} />
        </HStack>
      </PopoverTrigger>
      <Portal>
        <PopoverContent maxW="fit-content">
          <PopoverArrow />
          <PopoverBody m={0} p={0}>
            <SearchList
              searchField="Roll up"
              currentValue={metric.rollUp}
              initialFocusRef={initialFocusRef}
              onChange={onChange}
            >
              <SearchListItem text="Sum" value={RollUpEnum.Sum} />
              <SearchListItem text="Average" value={RollUpEnum.Average} />
              <SearchListItem text="Amount increased" value={RollUpEnum.Increase} />
              <SearchListItem text="Last month available" value={RollUpEnum.LastMonthAvailable} />
            </SearchList>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  )
}

export default CardRollupType
