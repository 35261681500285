import { Divider, HStack, Spacer, useBreakpointValue, Stack } from '@chakra-ui/react'
import type { FC } from 'react'

import BasicCardTypePopover from '@app/next/basicCardTypePopover'
import RichTextInput from '@app/next/forms/inline/richTextInput'
import RichTextInputWithLabel from '@app/next/forms/inline/richTextInputWithLabel'
import ViewerFieldWrapper from '@app/next/forms/inline/support/viewerFieldWrapper'
import DrawerHeaderActions from '@app/pages/maps/components/drawer/drawerHeaderActions'
import { CardTitle } from '@app/shared/cards/components'
import { DomainObjectType } from '@app/shared/cards/components/cardTypeSection'
import { PageHeaderSection } from '@app/shared/layout'
import type { MapDomainBasicCard } from '@app/types'

interface Props {
  basicCard: MapDomainBasicCard
  strategyId: string
}

const Header: FC<Props> = ({ basicCard, strategyId }) => (
  <PageHeaderSection>
    <HStack pl={4}>
      <DomainObjectType>
        <BasicCardTypePopover basicCard={basicCard} />
      </DomainObjectType>
      <Spacer />
      <DrawerHeaderActions objId={basicCard.id} objType={basicCard.classType} strategyId={strategyId} />
    </HStack>
    <Divider />
    <Stack px={4}>
      <CardTitle domainObject={basicCard} name="name" fontSize={useBreakpointValue({ base: 'lg', md: 'xl' })} />
      <ViewerFieldWrapper value={basicCard.description}>
        <RichTextInput domainObject={basicCard} name="description" placeholder="Description..." />
      </ViewerFieldWrapper>
      <ViewerFieldWrapper value={basicCard.goalHypothesis}>
        <RichTextInputWithLabel
          domainObject={basicCard}
          name="goalHypothesis"
          placeholder="Goal / Hypothesis..."
          label="Goal / Hypothesis"
        />
      </ViewerFieldWrapper>
      <ViewerFieldWrapper value={basicCard.results}>
        <RichTextInputWithLabel domainObject={basicCard} name="results" placeholder="Results..." label="Results" />
      </ViewerFieldWrapper>
    </Stack>
  </PageHeaderSection>
)

export default Header
