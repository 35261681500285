import { forwardRef } from 'react'

import { SelectInput } from '@app/shared/rawForms'
import type { Props as SelectInputProps } from '@app/shared/rawForms/selectInput'
import { RollUpEnum } from '@graphql/queries'

type Props = Omit<SelectInputProps, 'children'> & {
  metricId: string | null
}

const MetricRollUpSelect = forwardRef<HTMLSelectElement, Props>((props, ref) => (
  <SelectInput {...props} ref={ref}>
    <option value={RollUpEnum.Sum}>Sum</option>
    <option value={RollUpEnum.Average}>Average</option>
    <option value={RollUpEnum.Increase}>Amount Increased</option>
    <option value={RollUpEnum.LastMonthAvailable}>MoM, month over 3-month average, YoY</option>
  </SelectInput>
))

export default MetricRollUpSelect
