import { Button } from '@chakra-ui/react'
import type { FC } from 'react'
import { useCallback } from 'react'

import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import ConfirmPopover from '@app/shared/confirmPopover'
import { useOrganizationDefaultMetricStatIntervalsApplyMutation } from '@graphql/queries'

const ApplyOrganizationMetricStatIntervals: FC = () => {
  const { user } = useStoreCurrentUser()

  const organizationId = user?.organization?.id

  const [, update] = useOrganizationDefaultMetricStatIntervalsApplyMutation()
  const onConfirm = useCallback(async () => {
    await update({
      input: {
        organizationId
      }
    })
  }, [organizationId, update])

  return (
    <ConfirmPopover
      header="Apply intervals to all metrics"
      onConfirm={onConfirm}
      body="Are you sure you want to update the statistic intervals for all of your metrics? This cannot be undone."
      confirmButtonText="Confirm"
    >
      <Button size="sm" type="button">
        Apply intervals to all metrics
      </Button>
    </ConfirmPopover>
  )
}

export default ApplyOrganizationMetricStatIntervals
