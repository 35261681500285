import { Box, Flex, Text } from '@chakra-ui/react'
import type { UppyFile } from '@uppy/core'
import type { FC, FormEventHandler } from 'react'
import { useCallback, useEffect, useRef, useState } from 'react'
import type { OperationResult } from 'urql'

import ChatTextareaHelpText from './chatTextareaHelpText'

import useGetObject from '@app/hooks/useGetObject'
import ChatTextarea from '@app/pages/maps/components/aiChat/components/chatTextarea'
import CancelButton from '@app/shared/uploader/components/cancelButton'
import SubmitButton from '@app/shared/uploader/components/submitButton'
import useUppyInstance from '@app/shared/uploader/components/useUppyInstance'
import DoubleloopUploader from '@app/shared/uploader/dlUploaderV2'
import type { FileTicketMeta, RemoteFile } from '@app/shared/uploader/types'
import type { PipelineRunMutation } from '@graphql/queries'
import { usePipelineRunMutation } from '@graphql/queries'
import type { PipelineEnum } from '@graphql/types'

type Props = {
  strategyId: string
  pipelineId: PipelineEnum
  chatId?: string | null
  onSuccess?: (data: OperationResult<PipelineRunMutation>) => void
}

type Response = { body?: RemoteFile; status: number; bytesUploaded?: number; uploadURL?: string }

const PromptForm: FC<Props> = ({ pipelineId, strategyId, chatId = null, onSuccess = null }) => {
  const [fileIds, setFileIds] = useState<string[]>([])
  const inputRef = useRef<HTMLTextAreaElement>(null)
  const [, runPipeline] = usePipelineRunMutation()
  const [stateIsDisabled, setStateIsDisabled] = useState(false)
  const chat = useGetObject(chatId, 'chat')
  const isProcessing = chat?.processedState === 'processing' || chat?.processedState === 'waiting'
  const error = chat?.processedState === 'error'
  const borderColor = error ? 'error' : 'normal'
  const isDisabled = stateIsDisabled || isProcessing

  const onUploadSuccess = useCallback((_file: UppyFile<FileTicketMeta, RemoteFile>, response: Response) => {
    setFileIds((prevFileIds) => [...prevFileIds, response.body.id])
  }, [])

  const [uppyInstance] = useState(useUppyInstance({ dropTarget: inputRef.current, onUploadSuccess }))

  const onSubmit: FormEventHandler<HTMLFormElement> = useCallback(
    async (e) => {
      e.preventDefault()
      const formEntries = new FormData(e.currentTarget)
      const prompt = formEntries.get('prompt') as string

      if (!prompt) {
        return Promise.resolve()
      }

      inputRef.current.value = ''

      setStateIsDisabled(true)
      uppyInstance?.cancelAll()

      return runPipeline({
        input: {
          prompt,
          fileIds,
          chattable: { strategy: { id: strategyId } },
          pipelineId,
          chatId
        }
      })
        .then((result) => {
          setFileIds([])
          onSuccess?.(result)

          return result
        })
        .finally(() => {
          setStateIsDisabled(false)
        })
    },
    [fileIds, runPipeline, strategyId, chatId, onSuccess]
  )

  useEffect(() => {
    if (!isDisabled) {
      inputRef.current.focus()
    }
  }, [isDisabled, inputRef])

  return (
    <Box as="form" w="100%" onSubmit={onSubmit}>
      <Box pos="relative">
        <Box pos="relative" _after={{ position: 'absolute !important' }}>
          <ChatTextareaHelpText inputRef={inputRef} />
          <ChatTextarea
            ref={inputRef}
            borderColor={borderColor}
            isRequired={uppyInstance.getFiles().length === 0}
            isDisabled={isDisabled}
          />
        </Box>
        <Flex pos="absolute" zIndex="2" top="3" right="0">
          {isProcessing && <CancelButton chatId={chat?.id} px={0} />}
          <SubmitButton isDisabled={isDisabled} />
        </Flex>
        {chat?.errorMessage && (
          <Text mt="1" color="error" fontSize="xs">
            {chat.errorMessage}
          </Text>
        )}
        <Box mt={4}>
          <DoubleloopUploader isDisabled={isDisabled} uppyInstance={uppyInstance} dropTarget={inputRef.current} />
        </Box>
      </Box>
    </Box>
  )
}

export default PromptForm
