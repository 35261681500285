import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Button,
  ButtonGroup,
  Stack,
  StackDivider,
  HStack,
  Spacer,
  Text,
  Tooltip
} from '@chakra-ui/react'
import type { FC } from 'react'
import { forwardRef, useRef } from 'react'

import MetricDisplayFormatSection from './metricDisplayFormatSection'

import useCookie from '@app/hooks/useCookie'
import { METRIC_CARD_TYPE_COOKIE } from '@app/lib/globals'
import { strategyTypeOptionsFor } from '@app/pages/maps/components/helpers'
import AiPopulateDescriptionButton from '@app/pages/metrics/components/aiPopulateDescriptionButton'
import AiPopulateTechnicalDescriptionButton from '@app/pages/metrics/components/aiPopulateTechnicalDescriptionButton'
import MetricDeleteButton from '@app/pages/metrics/components/metricDeleteButton'
import MetricDisplay from '@app/pages/metrics/components/metricDisplay'
import MetricRollUpSelect from '@app/pages/metrics/components/metricRollUpSelect'
import MetricSegmentedDataTypeSelect from '@app/pages/metrics/components/metricSegmentedDataTypeSelect'
import StatIntervals from '@app/pages/metrics/components/statIntervals'
import SubscriptionSection from '@app/pages/metrics/components/subscriptionSection'
import { ButtonRow, FormAlert } from '@app/shared/forms'
import {
  Form,
  LabelAutocompleteInput,
  OwnerSelect,
  RichInput,
  SelectInput,
  TextInput,
  ToggleInput,
  useForm
} from '@app/shared/rawForms'
import type { MarkdownEditorRef } from '@app/shared/rawForms/markdownEditor/markdownEditor'
import type { MapDomainMetric } from '@app/types'
import { MetricPositiveDirectionsEnum } from '@graphql/types'

interface Props {
  metric?: MapDomainMetric
  onSuccess?: (metric: unknown) => void
  onCancel?: () => void
  onDelete?: () => void
}

type MetricDescriptionListProps = { metricId: string | null }

const MetricDescriptionLabel = forwardRef<MarkdownEditorRef, MetricDescriptionListProps>(({ metricId }, ref) => {
  if (!metricId) {
    return <Text>Description</Text>
  }

  return (
    <HStack>
      <Text>Description</Text>
      <Spacer />
      <AiPopulateDescriptionButton data-cy="metric-description-suggest" metricId={metricId} ref={ref} />
    </HStack>
  )
})

const MetricTechnicalDescriptionLabel = forwardRef<MarkdownEditorRef, MetricDescriptionListProps>(
  ({ metricId }, ref) => {
    if (!metricId) {
      return <Text>Technical description</Text>
    }

    return (
      <HStack>
        <Text>Technical description</Text>
        <Spacer />
        <AiPopulateTechnicalDescriptionButton
          data-cy="metric-technical-description-suggest"
          metricId={metricId}
          ref={ref}
        />
      </HStack>
    )
  }
)

const MetricForm: FC<Props> = ({
  metric = null,
  onCancel = () => {},
  onDelete = () => {},
  onSuccess = () => {},
  ...rest
}) => {
  const { errors } = useForm({ onSuccess })
  const descriptionRef = useRef<MarkdownEditorRef>(null)
  const technicalDescriptionRef = useRef<MarkdownEditorRef>(null)
  const [, setStrategyType] = useCookie(METRIC_CARD_TYPE_COOKIE)

  const rollUpSelect = (
    <MetricRollUpSelect
      metricId={metric?.id}
      name="rollUp"
      label="Roll Up Calculation"
      placeholder={null}
      errors={errors}
      isDisabled={metric?.calculated}
      isRequired={!metric?.calculated}
      defaultValue={metric?.rollUp}
    />
  )

  return (
    <Form method="post" id="metric-form" {...rest}>
      <FormAlert
        description={errors?.global?.message}
        title={`Failed to ${metric ? 'update' : 'create'} the metric!`}
      />
      <Accordion allowToggle defaultIndex={0}>
        <AccordionItem>
          <AccordionButton bg="bg.muted">
            <Box as="span" flex="1" textAlign="left">
              General
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel p={4}>
            <Stack divider={<StackDivider />} spacing="5">
              <TextInput
                name="name"
                label="Name"
                placeholder="Untitled metric"
                errors={errors}
                isRequired
                defaultValue={metric?.name}
              />
              <RichInput
                name="description"
                label={<MetricDescriptionLabel metricId={metric?.id} ref={descriptionRef} />}
                placeholder="Description (optional)"
                errors={errors}
                defaultValue={metric?.description || ''}
                ref={descriptionRef}
              />
              <RichInput
                name="technicalDescription"
                label={<MetricTechnicalDescriptionLabel metricId={metric?.id} ref={technicalDescriptionRef} />}
                placeholder="Technical description (optional)"
                defaultValue={metric?.technicalDescription || ''}
                errors={errors}
                ref={technicalDescriptionRef}
              />
              <TextInput
                name="externalUrl"
                label="URL"
                type="url"
                maxW="100%"
                helperText="If this data already lives in another tool, spreadsheet, report, or dashboard, you can link to it here."
                placeholder="Where can you see this data outside DoubleLoop?"
                errors={errors}
                defaultValue={metric?.externalUrl}
              />
              <TextInput
                name="externalUrlTitle"
                label="URL"
                maxW="100%"
                helperText="If you want to display different text for the URL, you can enter it here."
                placeholder="See more..."
                errors={errors}
                defaultValue={metric?.externalUrlTitle}
              />
              <HStack>
                <OwnerSelect
                  name="ownerId"
                  placeholder="None"
                  label="Owner"
                  errors={errors}
                  defaultValue={metric?.ownerId}
                />
                <SelectInput
                  name="strategyType"
                  placeholder=""
                  label="Strategy Type"
                  defaultValue={metric?.strategyType || 'input'}
                  onChange={(e) => setStrategyType(e.target.value)}
                  errors={errors}
                >
                  {strategyTypeOptionsFor('metric')}
                </SelectInput>
              </HStack>
              <LabelAutocompleteInput
                name="labels"
                label="Labels"
                defaultValue={metric?.labels || []}
                menuPortalTarget={document.body}
              />
            </Stack>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionButton bg="bg.muted">
            <Box as="span" flex="1" textAlign="left">
              Statistics
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel p={4}>
            <Stack divider={<StackDivider />} spacing="5">
              <Stack>
                <HStack>
                  {metric?.calculated ? (
                    <Tooltip label="For calculated metrics, the roll up calculation is determined by its component metrics.">
                      {rollUpSelect}
                    </Tooltip>
                  ) : (
                    rollUpSelect
                  )}
                  <MetricDisplayFormatSection metric={metric} errors={errors} />
                </HStack>
              </Stack>
              <ToggleInput label="Exclude weekends?" name="excludeWeekends" defaultValue={metric?.excludeWeekends} />
              <SelectInput
                name="positiveDirection"
                label="Positive direction"
                helperText="Examples: Up for active user count, Down for page load times"
                placeholder=""
                defaultValue={metric?.positiveDirection}
                errors={errors}
                isRequired
              >
                <option value={MetricPositiveDirectionsEnum.Up}>Up</option>
                <option value={MetricPositiveDirectionsEnum.Down}>Down</option>
              </SelectInput>

              <MetricDisplay metric={metric} errors={errors} />
              <ToggleInput
                label="Placeholder data?"
                name="containsPlaceholderData"
                defaultValue={metric?.containsPlaceholderData}
              />
            </Stack>
          </AccordionPanel>
        </AccordionItem>
        {metric && (
          <AccordionItem>
            <AccordionButton bg="bg.muted">
              <Box as="span" flex="1" textAlign="left">
                Statistic Intervals
              </Box>
              <AccordionIcon />
            </AccordionButton>

            <AccordionPanel p={4}>
              <StatIntervals metric={metric} errors={errors} />
            </AccordionPanel>
          </AccordionItem>
        )}
        <AccordionItem>
          <AccordionButton bg="bg.muted">
            <Box as="span" flex="1" textAlign="left">
              Segments
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel p={4}>
            <Stack divider={<StackDivider />} spacing="5">
              <MetricSegmentedDataTypeSelect
                name="segmentedDataType"
                label="Segmented Data Type"
                placeholder={null}
                errors={errors}
                isRequired
                defaultValue={metric?.segmentedDataType}
              />
            </Stack>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionButton bg="bg.muted">
            <Box as="span" flex="1" textAlign="left">
              Subscriptions
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel p={4}>
            <SubscriptionSection metric={metric} errors={errors} defaultValue={metric?.subscriptionSchedule} />
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
      <ButtonRow p={4}>
        {metric && <MetricDeleteButton metricId={metric.id} onDelete={onDelete} />}
        <ButtonGroup>
          <Button onClick={onCancel} variant="secondary">
            Cancel
          </Button>

          <Button type="submit" variant="primary">
            Save
          </Button>
        </ButtonGroup>
      </ButtonRow>
    </Form>
  )
}

export default MetricForm
