import { Center, Input, Text, VisuallyHidden } from '@chakra-ui/react'
import type { AwsBody } from '@uppy/aws-s3'
import type { UppyOptions } from '@uppy/core'
import type { ChangeEvent, ChangeEventHandler, FC } from 'react'
import { useEffect, useState } from 'react'

import { MAX_AWS_FILE_SIZE } from '@app/lib/globals'
import GoogleBigQueryCredentialInstructions from '@app/pages/metrics/components/metricSource/google/googleBigQueryCredentialInstructions'
import AwsUploader from '@app/shared/uploader/awsUploader'
import type { Credential } from '@graphql/types'

interface Props {
  credential?: Pick<Credential, 'file' | 'fileData'>
  onChange: ChangeEventHandler<HTMLFormElement>
}

type Meta = Record<string, unknown>

const GoogleBigQueryAuth: FC<Props> = ({ onChange, credential = {} }) => {
  const { file, fileData } = credential
  const [shrineHash, setShrineHash] = useState<string>(fileData ? JSON.stringify(fileData) : '')
  const [uploadedId, setUploadedId] = useState<string>(file?.metadata?.filename)

  const uppyOptions: UppyOptions<Meta, AwsBody> = {
    id: 'big-query-auth',
    autoProceed: true,
    locale: {
      strings: { chooseFiles: 'Upload a new credential file' },
      pluralize(_n: number): number {
        throw new Error('Function not implemented.')
      }
    },
    restrictions: {
      maxNumberOfFiles: 1,
      maxFileSize: MAX_AWS_FILE_SIZE,
      allowedFileTypes: ['application/json'],
      minFileSize: 0,
      maxTotalFileSize: 0,
      minNumberOfFiles: 0,
      requiredMetaFields: []
    }
  }

  const hashPresent = !!shrineHash
  useEffect(() => {
    onChange({} as ChangeEvent<HTMLFormElement>)
  }, [hashPresent, onChange])

  return (
    <>
      <GoogleBigQueryCredentialInstructions />
      <VisuallyHidden>
        <Input isRequired name="file" onChange={() => {}} type="text" value={shrineHash || ''} />
      </VisuallyHidden>

      <Center>
        <AwsUploader
          uppyOptions={uppyOptions}
          fileInput
          inputName=""
          onUploadSuccess={(newShrineHash, uppyFile) => {
            setUploadedId(uppyFile.name)
            setShrineHash(newShrineHash)
          }}
        />
      </Center>
      {uploadedId && (
        <Center>
          <Text color="gray.500" fontSize="sm">
            Attached credentials: {uploadedId}
          </Text>
        </Center>
      )}
    </>
  )
}

export default GoogleBigQueryAuth
