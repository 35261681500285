import type { FC } from 'react'
import { useEffect, useState } from 'react'

import { usePage } from '@app/shared/pagination'
import withSuspenseWrapper from '@app/shared/withSuspenseWrapper'
import { useStore } from '@app/store'
import { useTasksQuery } from '@graphql/queries'
import type { Task } from '@graphql/types'

interface Props {
  isLoading?: boolean
  setIsLoading?: (isLoading: boolean) => void
  limit?: number
  domainObjectType?: 'Metric'
  domainObjectId?: string
}

const TasksFetcher: FC<Props> = ({
  isLoading = false,
  setIsLoading = (_value) => {},
  limit = null,
  domainObjectId,
  domainObjectType
}) => {
  const { addObjectPage } = useStore.getState()
  const page = usePage()
  const [taskData, setTaskData] = useState(null)
  const [internalPage, setInternalPage] = useState(page)

  const variables: { page: number; limit?: number; domainObjectId?: string; domainObjectType?: 'Metric' } = { page }

  if (domainObjectId && domainObjectType) {
    variables.domainObjectId = domainObjectId
    variables.domainObjectType = domainObjectType
  }

  if (limit) {
    variables.limit = limit
  }

  const [{ data }] = useTasksQuery({
    variables,
    pause: !!taskData && page === internalPage
  })

  useEffect(() => {
    if (!isLoading && !data) {
      setIsLoading(true)
    }
  }, [isLoading])

  useEffect(() => {
    if (!data) {
      return
    }

    const { collection = [], metadata } = data.tasks
    addObjectPage('task', collection as Task[], metadata)

    setInternalPage(page)
    setTaskData(data)
    setIsLoading(false)
  }, [isLoading, page, data, domainObjectId, domainObjectType])

  return null
}

export default withSuspenseWrapper(TasksFetcher)
