import { Divider, Stack, Text, Link as ChakraLink, HStack, Icon } from '@chakra-ui/react'
import sortBy from 'lodash/sortBy'
import { memo } from 'react'
import { useShallow } from 'zustand/react/shallow'

import useGetObjects from '@app/hooks/useGetObjects'
import NavButton from '@app/shared/layout/shells/components/navButton'
import { useStore } from '@app/store'
import ICON_MAP from '@app/utils/iconMap'
import { showRouteByTypeAndId } from '@app/utils/routeHelpers'

const ROUTE_MAP = {
  Metric: 'metrics',
  Strategy: 'strategy'
}

const FavoritesSidebarList = () => {
  const defaultFavorites = useGetObjects('favorite')

  const favorites = useStore(
    useShallow((state) =>
      defaultFavorites
        .map((favorite) => {
          const favoritable = state[favorite.favoritable.classType]?.[favorite.favoritableId]

          return {
            ...favorite,
            favoritable
          }
        })
        .filter((f) => !!f.favoritable)
    )
  )

  if (!favorites || !favorites.length) {
    return null
  }

  return (
    <>
      <Stack key="favorites-list">
        <HStack mb={2} px={3} spacing={3}>
          <Icon as={ICON_MAP.Favorited} color="yellow.500" />
          <Text fontSize="sm" fontWeight="500">
            Favorites
          </Text>
        </HStack>
        {sortBy(favorites, ['favoritableType', 'favoritable.name']).map((favorite) => (
          <NavButton
            as={ChakraLink}
            size="sm"
            key={favorite.id}
            icon={ICON_MAP[favorite.favoritableType]}
            label={favorite.favoritable.name}
            href={showRouteByTypeAndId(`${ROUTE_MAP[favorite.favoritableType]}`, favorite.favoritableId)}
          />
        ))}
      </Stack>
      <Divider key="favorites-divider" />
    </>
  )
}

export default memo(FavoritesSidebarList)
