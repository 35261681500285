import {
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton
} from '@chakra-ui/react'
import type { FC } from 'react'
import ReactJson from 'react-json-view'

import { useMetricSourceActivityQuery } from '@graphql/queries'

interface Props {
  importId: string
  open: boolean
  onOpenChange: (args: { open: boolean }) => void
}

const MetricSourceActivityShow: FC<Props> = ({ importId, open, onOpenChange }) => {
  const [{ data }] = useMetricSourceActivityQuery({
    variables: { id: importId }
  })

  const parsed = JSON.parse(data?.metricSourceActivity?.payload)
  const src = typeof parsed === 'object' ? parsed : { value: parsed }

  return (
    <Modal isCentered isOpen={open} onClose={() => onOpenChange({ open: false })} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Import Data</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box overflow="auto" maxH="250px">
            <ReactJson displayDataTypes={false} enableClipboard={false} name={false} src={src} />
          </Box>
        </ModalBody>

        <ModalFooter>
          <Button mr={3} colorScheme="blue" onClick={() => onOpenChange({ open: false })}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default MetricSourceActivityShow
