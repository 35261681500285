import { Button, Stack, StackDivider, VisuallyHiddenInput } from '@chakra-ui/react'
import ahoy from 'ahoy.js'
import type { FormEventHandler } from 'react'
import { useState } from 'react'
import { useNavigation } from 'react-router-dom'

import useStoreCurrentUser from '@app/hooks/useStoreCurrentUser'
import { ButtonRow, FormAlert, FormContainer } from '@app/shared/forms'
import { EmailInput, Form, SelectInput, TextInput, ToggleInput } from '@app/shared/rawForms'
import useToast, { Notification } from '@app/shared/toast'
import timezones from '@app/utils/timezones'
import { actionMutation } from '@graphql/client'
import { UserUpdate } from '@graphql/documents/user.graphql'
import type { UserUpdateMutation } from '@graphql/queries'

const humanizeTimezone = (tz) => tz.replaceAll('_', ' ').replaceAll('/', ' - ')

const UserForm = () => {
  const { user } = useStoreCurrentUser()
  const navigation = useNavigation()
  const toast = useToast()
  const [errors, setErrors] = useState({})
  const onSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault()
    const formData = new FormData(e.currentTarget)
    const input = Object.fromEntries(formData.entries())

    try {
      const response = await actionMutation<UserUpdateMutation>(UserUpdate, input)

      ahoy.track('user:edited', input)

      if (response.error) {
        throw response
      }

      const { errors: updateErrors } = response.data.userUpdate

      if (updateErrors.length) {
        setErrors(updateErrors)
      }

      toast({
        title: 'Updating your profile',
        position: 'bottom-right',
        status: 'success',
        render: (props) => <Notification {...props} />
      })
    } catch (error) {
      setErrors(error)
    }
  }

  return (
    <Form onSubmit={onSubmit} id="user-form">
      <VisuallyHiddenInput defaultValue={user.id} name="userId" />
      <FormContainer>
        <FormAlert
          description={(errors as { global: { message: string } })?.global?.message}
          title="Failed to update your profile!"
        />
        <Stack divider={<StackDivider />} spacing="5">
          <TextInput
            label="Name"
            name="name"
            isRequired
            placeholder="Whats your name?"
            defaultValue={user.name}
            errors={errors}
          />
          <EmailInput
            name="email"
            label="Email"
            placeholder="Email address"
            defaultValue={user.email}
            isRequired
            errors={errors}
          />
          <TextInput
            label="Job title"
            name="jobTitle"
            placeholder="Whats your job title?"
            defaultValue={(user as unknown as { jobTitle: string }).jobTitle}
            errors={errors}
          />
          <SelectInput
            name="timeZone"
            label="Time zone"
            placeholder={null}
            defaultValue={user.timeZone}
            errors={errors}
          >
            {timezones.map((timezone) => (
              <option key={timezone} value={timezone}>
                {humanizeTimezone(timezone)}
              </option>
            ))}
          </SelectInput>
          <ToggleInput
            name="optedInToAnalytics"
            label="Opt into platform analytics"
            defaultValue={user.optedInToAnalytics}
          />
        </Stack>
        <ButtonRow bg="bg.subtle">
          <Button isLoading={navigation.state === 'submitting'} type="submit" variant="primary">
            Save changes
          </Button>
        </ButtonRow>
      </FormContainer>
    </Form>
  )
}

export default UserForm
