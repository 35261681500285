import { ListItem, MenuItem, UnorderedList, Text } from '@chakra-ui/react'
import type { FC, ReactNode } from 'react'
import { useCallback, useState } from 'react'

import { METRIC_SOURCES } from '@app/lib/globals'
import ConfirmPopover from '@app/shared/confirmPopover'
import useToast from '@app/shared/toast'
import withSuspenseWrapper from '@app/shared/withSuspenseWrapper'
import { useStore } from '@app/store'
import { useCredentialMetricNamesQuery } from '@graphql/queries'
import type { Credential as GqlCredential } from '@graphql/types'

type Credential = Pick<GqlCredential, 'id' | 'classType' | 'label' | 'sourceName'>

type Props = {
  credential: Credential
  children?: ReactNode
}

const defaultChildren = <MenuItem aria-label="Delete metric integration">Delete integration...</MenuItem>

const displayName = (credential: Credential) => {
  const baseDisplay = METRIC_SOURCES[credential.sourceName]?.display || ''

  if (credential.label) {
    return `${baseDisplay} - ${credential.label}`
  }

  return baseDisplay
}

const MetricIntegrationDeleteButton: FC<Props> = ({ credential, children = defaultChildren }) => {
  const toast = useToast()
  const [opened, setOpened] = useState(false)
  const deleteObject = useStore.use.deleteObject()

  const onOpen = useCallback(() => setOpened(true), [])

  const [{ data }] = useCredentialMetricNamesQuery({
    variables: {
      id: credential.id
    },
    pause: !opened
  })

  const deleteCredential = async () => {
    const result = await deleteObject(credential)

    if (result.error) {
      toast({
        title: 'Error deleting metric integration',
        description: result.error.message
      })
    } else if (result.data.crudOperation.errors.length > 0) {
      toast({
        title: 'Error deleting metric integration',
        description: result.data.crudOperation[0].message
      })
    }
  }

  const metricNames = data?.credential?.metrics?.map((m) => m.name) || []

  const body = (
    <>
      <Text>
        Are you sure you want to delete the metric integration &quot;{displayName(credential)}&quot;? You can’t undo
        this.
      </Text>
      {metricNames.length > 0 && (
        <>
          <Text mt={4} fontWeight="bold">
            This will disconnect the sources of the following metrics:
          </Text>
          <UnorderedList mt={2}>
            {metricNames.map((name) => (
              <ListItem key={name}>{name}</ListItem>
            ))}
          </UnorderedList>
        </>
      )}
    </>
  )

  return (
    <ConfirmPopover
      onConfirm={deleteCredential}
      onOpen={onOpen}
      header="Delete metric integration"
      body={body}
      confirmButtonText="Delete metric integration"
    >
      {children}
    </ConfirmPopover>
  )
}

export default withSuspenseWrapper(MetricIntegrationDeleteButton)
