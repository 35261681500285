import { Box, Container, Stack } from '@chakra-ui/react'
import type { FC, PropsWithChildren } from 'react'
import { useParams } from 'react-router-dom'

import useGetObjectPage from '@app/hooks/useGetObjectPage'
import Hero from '@app/next/changelog/components/hero'
import EmptyState from '@app/shared/emptyState/emptyState'
import AggregatedEntities from '@app/shared/entities/aggregatedEntities'
import { PageStack } from '@app/shared/layout'
import Pagination from '@app/shared/pagination'
import withAwait from '@app/shared/withAwait'
import type { Nodes } from '@app/types'

const Page: FC<PropsWithChildren> = ({ children }) => {
  const { strategyId } = useParams()

  return (
    <PageStack>
      <Container>
        <Hero strategyId={strategyId} />
        <Stack spacing={6}>{children}</Stack>
      </Container>
    </PageStack>
  )
}

const List: FC = () => {
  const changelog = useGetObjectPage('entity')
  const { collection, metadata } = changelog || {}
  const { nodeType } = useParams()

  if (collection.length === 0) {
    return (
      <Page>
        <EmptyState title="No changelog entries found" />
      </Page>
    )
  }

  return (
    <Page>
      <Box>
        <AggregatedEntities entities={collection} nodeType={nodeType as Nodes} />
      </Box>
      <Pagination px={6} pb={4} {...metadata} />
    </Page>
  )
}

export default withAwait(List, 'changelog')
