import { Button, Skeleton, Stack, StackDivider, VisuallyHiddenInput } from '@chakra-ui/react'
import set from 'lodash/set'
import type { FC, FormEventHandler } from 'react'
import { useCallback, useState } from 'react'
import Img from 'react-cloudimage-responsive'
import { useAsyncValue, useNavigation } from 'react-router-dom'

import useGetObjectsByProperties from '@app/hooks/useGetObjectsByProperties'
import { MAX_AWS_FILE_SIZE } from '@app/lib/globals'
import SsoForm from '@app/pages/settings/organization/components/ssoForm'
import StatIntervals from '@app/pages/settings/organization/components/statIntervals'
import { ButtonRow, FormAlert, FormContainer } from '@app/shared/forms'
import GatedFeature from '@app/shared/gatedFeature'
import { TimeInput, ColorInput, Form, TextInput, UppyFileInput, useForm, ToggleInput } from '@app/shared/rawForms'
import RootFormControl from '@app/shared/rawForms/rootFormControl'
import withAwait from '@app/shared/withAwait'
import type { Organization } from '@graphql/queries'
import { useOrganizationUpdateMutation } from '@graphql/queries'
import type { OrganizationUpdateInput } from '@graphql/types'

interface Props {
  logo?: string
}

const LogoPreview: FC<Props> = ({ logo = null }) => {
  const navigation = useNavigation()

  if (!logo) {
    return null
  }

  if (navigation.state === 'submitting' || navigation.state === 'loading') {
    return <Skeleton w="180px" h="50px" />
  }

  const params = { w: '180', org_if_sml: '1', func: 'bound' }

  return <Img params={params} src={logo} alt="logo" lazyLoading={false} doNotReplaceURL />
}

const OrganizationForm = () => {
  const [uploading, setUploading] = useState(false)
  const { errors } = useForm({})
  const organization = useAsyncValue() as Organization
  const googleSheet = useGetObjectsByProperties('googleSheet', { organizationLevel: true })?.[0]
  const [, updateOrganization] = useOrganizationUpdateMutation()

  const onSubmit: FormEventHandler<HTMLFormElement> = useCallback(
    async (e) => {
      e.preventDefault()
      const form = e.currentTarget
      const data = new FormData(form)
      const entries = Array.from(data.entries())

      const input: OrganizationUpdateInput = {
        organizationId: organization?.id
      }

      for (const [key, value] of entries) {
        set(input, key, value)
      }

      try {
        const response = await updateOrganization({ input })

        if (response?.data?.organizationUpdate?.organization) {
          form.reset()
        }
      } catch (_e) {
        // nothing
      }
    },
    [organization?.id, updateOrganization]
  )

  return (
    <Stack spacing={{ base: 4, sm: 6 }}>
      <Form onSubmit={onSubmit} id="organization-form">
        <VisuallyHiddenInput defaultValue={organization?.id} name="organizationId" />
        <FormContainer
          title="General"
          subtitle="These details are used to customize your experience and changelogs that you can share with stakeholders or customers."
        >
          <FormAlert
            description={(errors as { global: { message: string } })?.global?.message}
            title="Failed to update your organization!"
          />
          <Stack divider={<StackDivider />} spacing="5">
            <TextInput
              name="name"
              label="Name"
              placeholder="Acme Co."
              errors={errors}
              defaultValue={organization?.name}
              isRequired
            />
            <TextInput
              name="homepageUrl"
              label="Homepage URL"
              placeholder="https://acme.co"
              defaultValue={organization?.homepageUrl}
              errors={errors}
            />
            <TextInput
              name="twitterUrl"
              label="Twitter handle"
              placeholder="@acme"
              defaultValue={organization?.twitterUrl}
              errors={errors}
            />
            <ColorInput
              name="primaryColor"
              label="Primary color"
              defaultValue={organization?.primaryColor || 'blue.700'}
              errors={errors}
            />
            <UppyFileInput
              label="Logo"
              name="logo"
              fileTypes={['image/gif', 'image/jpeg', 'image/png']}
              maxFileSize={MAX_AWS_FILE_SIZE}
              errored={!!(errors as { shrineHash?: string })?.shrineHash}
              previewElement={<LogoPreview logo={organization?.logo} />}
              onUploadStart={() => setUploading(true)}
              onUploadSuccess={() => setUploading(false)}
              onUploadError={() => setUploading(false)}
            />
            <TimeInput
              label="Preferred data import time"
              name="defaultImportTime"
              defaultValue={organization?.defaultImportTime || '08:00'}
            />

            <RootFormControl label="Default statistic intervals">
              <StatIntervals organization={organization} errors={errors} />
            </RootFormControl>

            {googleSheet && (
              <ToggleInput
                label="Auto-import Google sheet data"
                name="autoImportGoogleSheet"
                defaultValue={organization?.autoImportGoogleSheet}
                errors={errors}
              />
            )}
          </Stack>
          <ButtonRow>
            <Button isDisabled={uploading} type="submit" variant="primary">
              Save settings
            </Button>
          </ButtonRow>
        </FormContainer>
      </Form>

      <GatedFeature featureId="feature-sso">
        {({ entitlement }) => {
          if (!entitlement) {
            return null
          }

          if (entitlement && !entitlement.hasAccess) {
            return null
          }

          return (
            <Form method="post" id="organization-sso-form" encType="multipart/form-data">
              <VisuallyHiddenInput defaultValue={organization?.id} name="organizationId" />
              <SsoForm organization={organization} errors={errors} loading={uploading} />
            </Form>
          )
        }}
      </GatedFeature>
    </Stack>
  )
}

export default withAwait(OrganizationForm, 'organization')
